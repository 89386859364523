import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import colorConfigs from '../../../configs/colorConfigs';


const UsageGraph = () => {

    const series = [{
      name: 'Results',
      data: [29, 44, 55, 57, 56, 61, 58, 63, 60, 66, 76, 82]
    }]
    

    const options: ApexOptions = {
          chart: {
            type: 'bar',
            height: 200
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: false,
              columnWidth: '55%',
              borderRadius: 5,
              borderRadiusApplication: 'end'
            },
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          colors: [
            colorConfigs.dashboard.dark_red,
            colorConfigs.dashboard.light_grey,
         ],
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          yaxis: {
            title: {
              text: 'Results'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val:any) {
                return val + " results"
              }
            }
          }
        };

    

    return (
      <div data-testid="div-7w5y">
        <div data-testid="div-r8xt" id="chart">
            <ReactApexChart 
            type="bar"
            options={options}
            series={series}
            height={options.chart?.height}
             />
          </div>
        <div data-testid="div-60v5" id="html-dist"></div>
      </div>
    );
  }

export default UsageGraph