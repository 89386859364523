import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs, TextField, IconButton } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import StripCard from "./StripCard";
import { ImageItem, Strip } from "../CustomHooks";

interface TabData {
  position: number;
  label: string;
  data?: Strip;
}

interface TabbedStripsProps {
  dataset_id?: any;
  image?: ImageItem;
  onStateChange: (state: Strip) => void;
  onError: (key: string, error?: string) => void;
  strips: Strip[];
  controlLineCorrection: boolean;
}

const TabbedStripsCard: React.FC<TabbedStripsProps> = ({
  dataset_id,
  image,
  onStateChange,
  onError,
  strips,
  controlLineCorrection
}) => {
  const defaultTab: TabData = {
    position: 1,
    label: "Strip 1",
  };
  const [tabs, setTabs] = useState<TabData[]>([defaultTab]);
  const [selectedTab, setSelectedTab] = useState(0);

  // If dataset changes in create Datarun page default states
  useEffect(() => {
      if (dataset_id) {
        setTabs([defaultTab])
        setSelectedTab(0)
      }
  }, [dataset_id])
  

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleAddTab = () => {
    const getMaxPosition = (t: TabData[]): number => {
      if (t.length === 0) return 1; // Handle empty array
      return t.reduce((max, tab) => Math.max(max, tab.position), -Infinity);
    };

    const newPosition = tabs.length;
    setTabs((prev) => {
      const newPosition = getMaxPosition(prev) + 1;
      return [
        ...tabs,
        {
          position: newPosition,
          label: `Strip ${newPosition}`,
        },
      ];
    });
    setSelectedTab(newPosition);
  };

  const handleLabelChange = (position: number, newLabel: string) => {
    setTabs(
      tabs.map((tab) =>
        tab.position === position ? { ...tab, label: newLabel } : tab
      )
    );
  };

  useEffect(() => {
    const tabsData: TabData[] = strips.length
      ? strips.map((item: Strip) => ({
          position: item.position,
          label: item.label,
          data: item,
        }))
      : [];
    setTabs(tabsData.length ? tabsData : [defaultTab]);
    setSelectedTab(0);
  }, [strips]);

  return (
    <Box data-testid="Box-9dna" sx={{ width: "100%", typography: "body1" }}>
      <Tabs data-testid="Tabs-odmy"
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Strips"
        indicatorColor="secondary"
      >
        {tabs.map((tab, index) => (
          <Tab data-testid="Tab-as3w"
            key={index}
            disableRipple
            label={
              <TextField
                value={tab.label}
                onChange={(e) =>
                  handleLabelChange(tab.position, e.target.value)
                }
                variant="standard"
                slotProps={{
                  input: {
                    disableUnderline: true,
                  },
                }}
              />
            }
            wrapped
          />
        ))}
        <IconButton data-testid="IconButton-x0vi" onClick={handleAddTab} color="primary">
          <AddIcon />
        </IconButton>
      </Tabs>
      {tabs.map((tab, index) => (
        <Box data-testid="Box-t5qy"
          key={index}
          role="tabpanel"
          hidden={selectedTab !== index}
          sx={{ p: 3 }}
        >
          <StripCard
            dataset_id={dataset_id}
            image={image}
            onStateChange={onStateChange}
            onError={onError}
            controlLineCorrection={controlLineCorrection}
            {...tab}
          />
        </Box>
      ))}
    </Box>
  );
};

export default TabbedStripsCard;
