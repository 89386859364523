import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Typography,
  Box,
  Link,
  Tooltip,
  IconButton,
} from "@mui/material";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ImageItem } from "../CustomHooks";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import colorConfigs from "../../../configs/colorConfigs";

interface ImageGridProps {
  setSelectedImage: (selectedImage: ImageItem | undefined) => void;
  selectedImage?: ImageItem;
  onDoubleClick?: () => void;
  datasetId?: string;
}

const ImageGrid: React.FC<ImageGridProps> = ({
  selectedImage,
  setSelectedImage,
  onDoubleClick,
  datasetId,
}) => {
  const datasetFilter = datasetId ? `&dataset_id=${datasetId}` : "";
  const authData = useSelector((state: RootState) => state.auth);
  const [items, setItems] = useState<ImageItem[]>([]);
  const [nextPage, setNextPage] = useState<string | null>(
    `results/images?limit=30${datasetFilter}`,
  );
  const [loading, setLoading] = useState(false);

  console.log("ImageGrid:", selectedImage);

  const fetchImages = useCallback(
    async (nextUrl: string | null, access: string | null) => {
      if (!nextUrl || loading || !access) return;
      setLoading(true);
      try {
        const response = await api.get(nextUrl, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });

        setItems((prevItems: ImageItem[]) => {
          const existingUrls = new Set(
            prevItems.map((item: ImageItem) => item.image_url),
          );
          const newItems = response.data.results.filter(
            (item: ImageItem) => !existingUrls.has(item.image_url),
          );
          return newItems.length ? [...prevItems, ...newItems] : prevItems;
        });
        if (response.data.next) {
          setNextPage(`${response.data.next}${datasetFilter}`);
        } else {
          setNextPage(null);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    },
    [loading, datasetFilter],
  );

  const observer = useRef<IntersectionObserver | null>(null);

  const lastImageRef = useCallback(
    async (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      if (node) {
        observer.current = new IntersectionObserver(async (entries) => {
          if (entries[0].isIntersecting && nextPage) {
            await fetchImages(nextPage, authData.access);
          }
        });

        observer.current.observe(node);
      }
    },
    [loading, nextPage, authData.access, fetchImages],
  );

  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  const handleImageDoubleClick = (image: ImageItem) => {
    setSelectedImage(image);
    if (onDoubleClick) onDoubleClick();
  };

  useEffect(() => {
    if (authData.isAuthenticated) {
      fetchImages(nextPage, authData.access);
    }
  }, [nextPage, authData.access]);

  return (
    <Box data-testid="Box-n8q5">
      <Box
        data-testid="Box-633a"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(200px, 1fr))",
          gap: 2,
        }}
      >
        {items.map((item, index) => (
          <Box
            data-testid="Box-svb4"
            key={item.image_url}
            ref={index === items.length - 1 ? lastImageRef : null}
            sx={{
              padding: 1,
              textAlign: "center",
            }}
          >
            <img
              data-testid="img-yd96"
              src={item.image_url}
              alt={item.image_url}
              width="100%"
              style={{
                cursor: "pointer",
                border:
                  selectedImage?.image_url === item.image_url
                    ? "5px solid blue"
                    : "none",
              }}
              onClick={() => handleImageSelect(item)}
              onDoubleClick={() => handleImageDoubleClick(item)}
            />
            <Box data-testid="Box-ilo7" sx={{ textAlign: "start" }}>
              <Typography
                data-testid="Typography-irsp"
                variant="body2"
                color="textSecondary"
              >
                <strong>Result ID:</strong> {item.result_id}
              </Typography>
              <Typography
                data-testid="Typography-xulu"
                variant="body2"
                color="textSecondary"
              >
                <strong>Timestamp:</strong>{" "}
                {item.timestamp
                  ? dayjs(item.timestamp).format("YYYY-MM-DD HH:mm:ss")
                  : ""}
              </Typography>
            </Box>
            <Tooltip
              data-testid="Tooltip-amhs"
              arrow
              placement="top"
              title="View Result"
            >
              <IconButton
                data-testid="IconButton-djo9"
                component={RouterLink}
                to={`/results/${item.result_id}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ p: 0, m: 0, transition: "transform 0.3s ease" }}
              >
                <VisibilityOutlinedIcon
                  sx={{ p: 0, m: 0, color: colorConfigs.sidebar.color }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        ))}
      </Box>
      {loading && (
        <div
          data-testid="div-ww8r"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <CircularProgress data-testid="CircularProgress-a9sf" />
        </div>
      )}
    </Box>
  );
};

export default ImageGrid;
