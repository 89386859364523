import React from "react";
import { Box, Typography, Stepper, Step, StepLabel } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";

interface StepperCardProps {
  steps: string[];
  activeStep: number;
  title: string;
}

const StepperCard: React.FC<StepperCardProps> = ({
  steps,
  activeStep,
  title,
}) => {
  return (
    <Box data-testid="Box-d0mt"
      sx={{
        position: "fixed", // Fixes the stepper on the page
        top: 0, // Adjust this to position the stepper at the desired vertical height
        left: 0, // Adjust this for horizontal positioning
        width: "100%", // Full width or adjust as needed
        zIndex: 1000, // Ensures it stays above other elements
        backgroundColor: colorConfigs.sidebar.bg,
        // p: 2,
        paddingTop: 2,
        paddingBottom: 1,
        // border: "1px solid",
        // borderColor: "grey.300",
        // borderRadius: 2,
        // boxShadow: 3, // Adds a shadow for better visibility
      }}
    >
      <Box data-testid="Box-qnr8"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Stacks vertically on small screens
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2, // Spacing between title and stepper
          mt: 6,
        }}
      >
        {title && (
          <Typography data-testid="Typography-xpz6" sx={{ fontSize: 24, fontWeight: "bold", mb: 2, flex: 1 }}>
            {title}
          </Typography>
        )}
        <Stepper data-testid="Stepper-bf4d"
          activeStep={activeStep}
          alternativeLabel
          sx={{ mb: 2, flex: 2 }}
        >
          {steps.map((label, index) => (
            <Step data-testid="Step-iplx" key={label}>
             <StepLabel data-testid="StepLabel-vs72"
                sx={{
                '& .MuiStepIcon-root': {
                    color: index < activeStep ? colorConfigs.buttons.active : undefined, // Green for completed
                },
                '& .MuiStepIcon-text': {
                    fill: '#fff', // Optional: Adjust text color for contrast
                },
                }}
            >
            <Typography data-testid="Typography-gqxt" sx={{fontWeight: 'bold', color: colorConfigs.text}}> {label}</Typography>
             
            </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default StepperCard;
