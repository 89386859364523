import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Badge, Box, Button, Grid, IconButton, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Tooltip, Typography, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { SubscriptionSessionStorageKey, SubscriptionSessionStorageModel, SubscriptionTableParameterStorageService } from "../../../services/sessionStorage";
import RefreshIcon from '@mui/icons-material/Refresh';
import colorConfigs from "../../../configs/colorConfigs";
import { Link as RouterLink }from 'react-router-dom';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import { tsXLXS } from 'ts-xlsx-export';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RareButton from "../buttons/RareButton";

const StyledMenu = styled((props: any) => (
    <Menu data-testid="Menu-nnop"
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface SubscriptionsTableProps {
    data: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    needToRefreshData: boolean;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setSubscription: (detail: any) => void;
    setPopup: (detail: any) => void;
    fetchData: () => void;
  }

const SubscriptionsTable: React.FC<SubscriptionsTableProps> = ({
  data,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  needToRefreshData,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setSubscription,
  setPopup,
  fetchData
}) => {

    console.log("Subscriptions", data)
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div data-testid="div-ofew" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            ID<br />&nbsp;
            </div>, 
        },  
        {
            accessorKey: 'name',
            header: "Name",
            Header:() => <div data-testid="div-8m7k" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Name<br />&nbsp;
                        </div>,
        },
        {
          accessorKey: 'price_per_month',
          header: "Price Per Month",
          Header:() => <div data-testid="div-ssug" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                          Price/<br />Month
                      </div>,
      },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type SubscriptionSessionStorageKeyType = keyof SubscriptionSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: SubscriptionSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        SubscriptionTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(SubscriptionSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(SubscriptionSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(SubscriptionSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(SubscriptionSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(SubscriptionSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(SubscriptionSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);


    return <MaterialReactTable
            columns={columns}
            data={data}
            state={{
              isLoading,
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              showGlobalFilter
            }}
            onSortingChange={setSortingFunction}
            onColumnVisibilityChange={setColumnVisibilityFunction}
            onColumnFiltersChange={setFilterChangeFunction}
            onPaginationChange={setPaginationChangeFunction}
            onGlobalFilterChange={setGlobalFilterChangeFunction}
            onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
            defaultColumn={{    
              minSize: 10,
              maxSize: 160,
              size: 10,
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"
            // enableRowActions
            initialState={{
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              density: 'compact',
              columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
            }}

            // TABLE STYLING IS IN THE THEME
            displayColumnDefOptions={{
              'mrt-row-actions': {
              header: "Actions", // Renames Actions Column
              },
          }}

          muiTopToolbarProps={({ table }) => ({
              sx: (theme: any) => ({
                ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
              }),
            })}

          muiTableHeadProps={{
              sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
            }}

          muiTableHeadCellProps={({ column }) => ({
              sx: (theme: any) => ({
                ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
                ...(column.id === 'mrt-row-select' && {
                  maxWidth: '50px',
                  width: '50px',
                }),
              }),
            })}

          muiFilterTextFieldProps={{
              sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
            }}

          muiTableBodyRowProps={({ row }) => ({
              onClick: (event) => {
                  console.log(row.original);
                  setSubscription(row.original);
                  setPopup('Edit');
              },
              sx: (theme: any) => ({
                  ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
                  backgroundColor:
                  row.index % 2 === 0
                      ? colorConfigs.tables.firstRow
                      : colorConfigs.tables.secondRow,
              }),
              })}

          muiTableBodyProps={{
              sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
              }}
          
          muiPaginationProps={{
                rowsPerPageOptions: [250, 500, 1000],
                sx: {
                    padding: 0,  
                    margin: 0,   
                    '& .MuiTablePagination-root': {
                    padding: 0,  
                    margin: 0,   
                    },
                    '& .MuiToolbar-root': {
                    padding: 0,
                    margin: 0,
                    minHeight: '0px',
                    },
                    '& .MuiTablePagination-selectLabel': {
                    margin: 0,
                    },
                    '& .MuiTablePagination-displayedRows': {
                    margin: 0,
                    },
                    '& .MuiTablePagination-actions': {
                    margin: 0,
                    },
                },
                }}

          muiTableBodyCellProps={({ cell }) => ({
              // TABLE BODY CELLS
              sx: {
              },
          })}

          muiBottomToolbarProps={{
            // BOTTOM TOOLBAR (Pagination)
            sx: {
              height: '41px',
              maxHeight: '41px',
              minHeight: '41px',
              position: 'sticky',
              bottom: 0,
              zIndex: 1,
              backgroundColor: '#fff',
              boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)', 
              margin: 0,
              padding: 0,
              '& .MuiToolbar-root': {
                padding: 0,
                margin: 0,
                paddingBottom: 0,
                paddingTop: 0,
              },
              '& .MuiBox-root': {
                p: 0,
              },
              '& .MuiIconButton-root': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiTablePagination-root': {
                margin: 0,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                color: colorConfigs.buttons.bg,
              },
              '& .MuiTablePagination-selectLabel': {
                color: colorConfigs.tables.headBg,
                padding: 0,
                margin: 0,
              },
              '& .MuiTablePagination-displayedRows': {
                color: colorConfigs.buttons.bg,
                padding: 0,
                margin: 0,
              },
              '& .MuiTablePagination-actions': {
                color: colorConfigs.tables.headBg,
                padding: 0,
                margin: 0,
              },
            },
          }}


          renderTopToolbarCustomActions={({ table }) => {

            const handleArchiveCycles = () => {
                table.getSelectedRowModel().flatRows.map(row => {
                    //Add archive logic
                });
            };

            const handleExportRows = (rows: any[]) => {
              const excelRows = [];
              const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
              const filename = `${now}_UserList`;
            
              for (let x = 0; x < rows.length; x++) {
                const user = rows[x].original;
                const formattedDateJoined = dayjs(user.date_joined).format('YYYY-MM-DD HH:mm:ss');
                const formattedLastLogin = user.last_login ? dayjs(user.last_login).format('YYYY-MM-DD HH:mm:ss') : '';
                
                excelRows[x] = {
                  "ID": user.id,
                  "Name": user.name,
                  "Description": user.description,
                  "Price Per Month": user.price_per_month,
                  "Tier": user.tier,
                  "Benefits": "",
                  "App Access": user.app_access ? "Yes" : "No",
                  "Portal Access": user.app_access ? "Yes" : "No",
                  "Unlimited Results": user.app_access ? "Yes" : "No",
                  "No of Users": user.number_of_users,
                  "Support Level": user.support_level,
                };
              }
            
              tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
            };
                

                return (
                  <Box data-testid="Box-6uer" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end'}}>
                  <Tooltip data-testid="Tooltip-9d2x" title="View action on selected data" placement="top" arrow>
                    <Box data-testid="Box-x6ym">
                      <RareButton
                        variant="outlined"
                        onClick={handleClick}
                        icon={<KeyboardArrowDownIcon />}
                      >
                        Bulk Actions
                      </RareButton> 
                    </Box>  
                  </Tooltip>
                  {/* <Tooltip data-testid="Tooltip-yjku" title="Refresh Data" placement="top" arrow>
                    <Box data-testid="Box-p51d">
                        <RareButton 
                          variant="outlined"
                          onClick={() => handleRefreshButton()}
                          center={true}
                          >
                          <RefreshIcon sx={{ p: 0, m: 0, display: 'flex', alignItems: 'center' }} />
                        </RareButton>
                    </Box>
                  </Tooltip> */}
                  <Tooltip data-testid="Tooltip-z4mr" title="Clear All Table Filters" placement="top" arrow>
                    <Box data-testid="Box-p74w">
                        <RareButton
                          variant="outlined"
                          onClick={() => handleRemoveAllFilters()}
                        >
                          <FilterAltOffOutlinedIcon sx={{ p: 0, m: 0, display: 'flex', alignItems: 'center' }} />
                        </RareButton>
                    </Box>
                  </Tooltip>
                  <Tooltip data-testid="Tooltip-laap" title="Create New Subscription" placement="top" arrow>
                    <Box data-testid="Box-ga9b">
                      <RareButton
                        onClick={() => setPopup("Create")}
                        variant="success"
                        icon={<FilterAltOffOutlinedIcon />}               
                      >
                        Create New Subscription
                      </RareButton>
                    </Box>
                  </Tooltip>
                  
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem data-testid="MenuItem-01d8" disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                      } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                        Export to Excel
                      </MenuItem>
                    </StyledMenu>
                    </Box>
                );
            }}
            
            renderRowActions={({ row, table }) => (

                <Box data-testid="Box-xxaf" sx={{ gap: '0.5rem', display: "flex" }}>
                {/* <Tooltip data-testid="Tooltip-2uf6"     
                    arrow
                    placement="left"
                    title="Cycles"
                    > */}
                    <Badge data-testid="Badge-raee"
                    badgeContent={row.original.total_cycles}
                    color={row.original.total_cycles ? 'primary' : 'default'}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                    '& .MuiBadge-badge': {
                        top: '13px',
                        right: '10px',
                        background: colorConfigs.tables.headBg,
                        opacity: 0.98
                    },
                    }}
                    overlap="circular"
                    >
                    <IconButton data-testid="IconButton-x2xu"
                        disabled={!row.original.total_cycles}
                        component={RouterLink}
                        to={`/cycles?user_id=${row.original.id}`}
                        state={{ clinicID: row.original.id }}
                        sx={{transition: 'transform 0.3s ease'}}
                    >
                        <RotateRightOutlinedIcon fontSize="large" sx={{color: row.original.total_cycles ? colorConfigs.sidebar.color: colorConfigs.sidebar.bg}} />
                    </IconButton>
                    </Badge>
                {/* </Tooltip> */}
                </Box>
              )}
        />
}
 
export default SubscriptionsTable;

