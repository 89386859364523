import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table'
import React, { useMemo } from 'react'
import colorConfigs from '../../../configs/colorConfigs';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

interface RecentDCMResultsTableProps {
  authData: any;
  rows: any[];
}

//example data type
interface LatestDCM {
  id: string;
  organisation_id: number | null;
  user_id: number | null;
  timestamp: string;
  sample_id: string;
  method_id: number | null;
  housing_id: number | null;
  test_configuration: {
    collection_mode: boolean | null;
  }
}
  
  //nested data is ok, see accessorKeys in ColumnDef below
  

const RecentDCMResultsTable: React.FC<RecentDCMResultsTableProps> = ({ 
  authData,
  rows,
}) => {
      const navigate = useNavigate()
    const columns: MRT_ColumnDef<any>[] = [
          {
            accessorKey: 'id', //access nested data with dot notation
            header: 'result',
            Header: () => (
                <div data-testid="div-8ym9" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                  Result<br />&nbsp;
                </div>
              ),
            Cell: ({ row }) => (
                <div data-testid="div-4mkb">
                  <ReceiptLongOutlinedIcon fontSize='small'/> {row.original.id} <br/> <TimerOutlinedIcon fontSize='small'/>{dayjs(row.original.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                </div>        
              ),
            size: 100,
          },
          {
            accessorKey: 'method_id', //normal accessorKey
            header: 'Method',
            Cell: ({ row }) => (
                <div data-testid="div-a0dd">
                  <AssignmentTurnedInOutlinedIcon fontSize='small'/> {row.original.method_id}
                </div>        
              ),
            size: 100,
          },
        ]

  return (
    <div data-testid="div-sms0" style={{width:580}}>
        <MaterialReactTable
        data={rows}
        columns={columns}
        enableColumnActions
        enableGlobalFilter ={false}
        enableGlobalFilterModes={false}
        enableColumnFilterModes={false}
        enableFilters={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableDensityToggle={false}
        initialState={{density: 'compact'}}
        enableFullScreenToggle={false}
        enableTopToolbar={false}
        muiTableHeadCellProps= {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              color: theme.palette.text.secondary,
            }),
          }}
        
        displayColumnDefOptions={{
            'mrt-row-actions': {
            header: "Actions", // Renames Actions Column
            },
        }}

        muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
                console.log(row.original);
                navigate(`/results/${row.original.id}`)
                //navigate(`/results/${row.original.id})
            },
            sx: (theme: any) => ({
                ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
                backgroundColor:
                row.index % 2 === 0
                    ? colorConfigs.tables.firstRow
                    : colorConfigs.tables.secondRow,
            }),
            })}
                
        />
    </div>
  )
}

export default RecentDCMResultsTable