import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  InputAdornment,
  Button,
  DialogActions,
  Slide,
  Box,
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Alert from '@mui/material/Alert'
import assets from '../../assets'
import { loginUser } from '../../redux/features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { unwrapResult } from '@reduxjs/toolkit';
import RareButton from './buttons/RareButton'

const loginpage = '/login'

// Transition component for the Dialog
const Transition = React.forwardRef(function Transition(
  props: React.ComponentProps<typeof Slide>,
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface SignInDialogProps {
  isAuthenticated: boolean | undefined
}

// SignInDialog component
const SignInDialog: React.FC<SignInDialogProps> = ({ isAuthenticated }) => {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [authfailed, setAuthFailed] = useState(false)
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setOpen(!isAuthenticated)
  }, [isAuthenticated])

  const handleClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
        setOpen(false)
    }
  }

  const handleEscape = () => {
    navigate(loginpage)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    var username = data.get('email') as string
    var password = data.get('password') as string
    try {
        setAuthFailed(false);
        const resultAction = await dispatch(loginUser({ username, password }));
        unwrapResult(resultAction); // If the promise is rejected, this will throw
        navigate(0)
    } catch (err) {
        console.error('Failed to login:', err);
        setAuthFailed(true);
      }
    };

  return (
    <Dialog data-testid="Dialog-wiw8"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle data-testid="DialogTitle-4rss">{'Please sign in to view this page'}</DialogTitle>
      <Box data-testid="Box-u3kz" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <DialogContent data-testid="DialogContent-pvdu">
          <DialogContentText id="alert-dialog-slide-description">
            {authfailed ? (
              <Alert data-testid="Alert-2np4" severity="error">Login failed, please try again</Alert>
            ) : (
              <></>
            )}
          </DialogContentText>
          <TextField data-testid="TextField-gt3s"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img data-testid="img-w0zf"
                    src={assets.images.emailicon}
                    alt="emailicon"
                    width="25px"
                    height="20px"
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField data-testid="TextField-b199"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
        </DialogContent>
        <DialogActions data-testid="DialogActions-34ks">
          <RareButton variant="outlined" icon={<ArrowBackIosIcon />} onClick={handleEscape}>
            Exit
          </RareButton>
          <RareButton variant="success" type="submit">Login</RareButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default SignInDialog
