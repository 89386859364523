export type Matrix3x3 = number[][];
export type Point = [number, number];

// Reshape the homography list into a 3x3 matrix
const reshapeTo3x3 = (flatList: number[]): Matrix3x3 => {
  if (flatList.length !== 9) {
    throw new Error("Input list must contain exactly 9 elements.");
  }
  return [
    [flatList[0], flatList[1], flatList[2]],
    [flatList[3], flatList[4], flatList[5]],
    [flatList[6], flatList[7], flatList[8]],
  ];
};

// Calculate the inverse of a 3x3 matrix
const inverse3x3 = (matrix: Matrix3x3): Matrix3x3 => {
  const [[a, b, c], [d, e, f], [g, h, i]] = matrix;

  const det = a * (e * i - f * h) - b * (d * i - f * g) + c * (d * h - e * g);
  if (det === 0) {
    throw new Error("Matrix is singular and cannot be inverted.");
  }

  const invDet = 1 / det;

  return [
    [
      (e * i - f * h) * invDet,
      (c * h - b * i) * invDet,
      (b * f - c * e) * invDet,
    ],
    [
      (f * g - d * i) * invDet,
      (a * i - c * g) * invDet,
      (c * d - a * f) * invDet,
    ],
    [
      (d * h - e * g) * invDet,
      (b * g - a * h) * invDet,
      (a * e - b * d) * invDet,
    ],
  ];
};

// Combine both steps
export const getHomographyInverse = (homography: number[]): Matrix3x3 => {
  const h = reshapeTo3x3(homography);
  return inverse3x3(h);
};

export const getTransformedPoint = (
  point: Point,
  inverseHomography: Matrix3x3
): Point => {
  const [px, py] = point;
  const denominator =
    inverseHomography[2][0] * px +
    inverseHomography[2][1] * py +
    inverseHomography[2][2];
  const transX =
    (inverseHomography[0][0] * px +
      inverseHomography[0][1] * py +
      inverseHomography[0][2]) /
    denominator;
  const transY =
    (inverseHomography[1][0] * px +
      inverseHomography[1][1] * py +
      inverseHomography[1][2]) /
    denominator;
  return [transX, transY];
};

export const arraysAreEqual = (arr1: number[][], arr2: number[][]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((subArr1, index) => {
    const subArr2 = arr2[index];

    // Check lengths and values of subarrays
    return (
      subArr1.length === subArr2.length &&
      subArr1.every((value, subIndex) => value === subArr2[subIndex])
    );
  });
};
