import React from "react";
import { Box, TextField, MenuItem, Typography, Tooltip, IconButton } from "@mui/material";
import { Field } from "formik";
import { SmallEditReadOnlyField, SmallTextEditField, TextEditField, TextEditReadOnlyField } from "../fields/fields";
import { HousingData } from "../CustomHooks";
import { Link as RouterLink }from 'react-router-dom';
import colorConfigs from "../../../configs/colorConfigs";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CabinOutlinedIcon from '@mui/icons-material/CabinOutlined';

type MethodDescriptionCardProps = {
  formik: {
    handleChange: (event: React.ChangeEvent<any>) => void;
    values: {
      test_name: string;
      version: number;
      description: string;
      id: string;
      status: string;
      housing: string;
    };
  };
  housing: HousingData[];
  housingError: string | null;
  isForDatasetCreation?: any | null;
  create?: boolean;
};

const MethodDescriptionCard: React.FC<MethodDescriptionCardProps> = ({
  formik,
  housing,
  housingError,
  isForDatasetCreation,
  create
}) => {
  return (
    <div data-testid="div-m7hc" style={{ flexGrow: 0, width: "100%" }}>
      <Box data-testid="Box-c2zl"
        sx={{
          p: 2,
          mb: 3, // margin-bottom
          mt: 1,
        }}
      >
        {/* Container for horizontally arranged fields */}
        <div data-testid="div-kfe0"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            width: "100%",
          }}
        >
          {/* Left side - name on top of version */}
          <div data-testid="div-mm7e"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Typography data-testid="Typography-wo9l" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Name</Typography>
            <Box data-testid="Box-zbq1" sx={{pl: 1.5}}>              
            <Field data-testid="Field-lk8b"
              name="test_name"
              type="text"
              className="form-control"
              // label="Method Name"
              placeholder="Name Method"
              component={create ? SmallTextEditField : SmallEditReadOnlyField}
              onChange={formik.handleChange}
              required
              style={{marginTop: 8}}
            />
            </Box>
          </div>

          {/* Right side - description as a text area */}
          <div data-testid="div-cyx0" style={{ flex: 2 }}>
          <Typography data-testid="Typography-wi5v" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Description</Typography>
          <Box data-testid="Box-tfsg" sx={{pl: 1.5}}>
            <Field data-testid="Field-x67r"
              name="description"
              type="text"
              className="form-control"
              // label="Method Description"
              placeholder="Describe Method"
              component={create ? SmallTextEditField : SmallEditReadOnlyField}
              multiline
              rows={3} // Use rows for textarea
              required
              onChange={formik.handleChange}
              style={{marginTop: 8}}
            />
          </Box>
          </div>
        </div>
        <div data-testid="div-jemr"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            width: "100%",
            paddingTop: "10px"
          }}
        >
        <div data-testid="div-a1s2" style={{ flex: 1 }}>
          <Box data-testid="Box-18x1" sx={{display: 'flex', alignItems: "center"}}>
          {isForDatasetCreation ? 
              <Typography data-testid="Typography-4ob5" sx={{  color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Housing</Typography>
              : 
              <Typography data-testid="Typography-2q1l" sx={{ color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Select Housing</Typography>
          }              
          <Box data-testid="Box-jzbk" sx={{pl: 1.5, display: 'flex', alignItems: "center", justifyContent: 'center', gap: 1}}>
              <Tooltip data-testid="Tooltip-moby"     
                  arrow
                  placement="top"
                  title="View Housing"
              >
                  <IconButton data-testid="IconButton-qko0"
                      disabled={!formik.values.housing}
                      component={RouterLink}
                      to={`/lf_configuration/housing/${formik.values.housing}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                          p: 0, m:0,
                          transition: 'transform 0.3s ease',
                          color: formik.values.housing ? colorConfigs.sidebar.color : colorConfigs.sidebar.bg,
                          opacity: formik.values.housing ? 1 : 0.5, // Apply opacity for disabled state
                          '&:disabled': {
                              color: colorConfigs.sidebar.color,
                              opacity: 0.5, // Ensure opacity remains for disabled state
                          },
                      }}
                  >
                      <VisibilityOutlinedIcon sx={{p: 0, m:0}} />
                  </IconButton>
              </Tooltip>
              <Tooltip data-testid="Tooltip-bzb7"     
                  arrow
                  placement="top"
                  title="Create New Housing"
                  >
                  <IconButton data-testid="IconButton-57ij"
                      component={RouterLink}
                      to={`/lf_configuration/housing/create`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{p: 0, m:0, transition: 'transform 0.3s ease'}}
                  >
                      <CabinOutlinedIcon sx={{p: 0, m:0, color: colorConfigs.sidebar.color}} />
                  </IconButton>
              </Tooltip>
              </Box>
          </Box>

          <Box data-testid="Box-o9od" sx={{pl: 1.5}}>
            
          <TextField data-testid="TextField-bje6"
            fullWidth
            select
            placeholder="Select Housing"
            margin="normal"
            autoFocus
            name="housing"
            value={formik.values.housing}
            onChange={formik.handleChange}
            required
            onClick={(e) => {
              if (isForDatasetCreation) {
                e.preventDefault(); // Prevent interaction
              }
            }}
            sx={{
              my: 1,
            }}
            InputProps={{
              readOnly: isForDatasetCreation, // Make field visually non-editable
              sx: {
                '& .MuiSelect-select': {
                    padding: 1,
                    paddingX: 2,
                },
            },
            }}
          >
            <MenuItem data-testid="MenuItem-rna6" key={0} value={""} sx={{color: colorConfigs.primary}}>
              <em>Select Housing</em>
            </MenuItem>
            {housing.map((h) => (
              <MenuItem data-testid="MenuItem-axdp" key={h.id} value={h.id}>
                {h.name}
              </MenuItem>
            ))}
          </TextField>

            </Box>

            {housingError && <p style={{ color: "red" }}>{housingError}</p>}
          </div>

            {/* Fields For Editing/Viewing only */}

            {/* THIS IS FOR EDIT */}
            <div data-testid="div-7h3p" style={{ flex: 1 }}>
            <Typography data-testid="Typography-jlg0" sx={{ fontSize: 18, fontWeight: 'bold', color: colorConfigs.primary }}>Version</Typography>
            <Box data-testid="Box-7jem" sx={{pl: 1.5}}>
            <Field data-testid="Field-giwf"
              name="version"
              type="text"
              className="form-control"
              // label="Method Version"
              placeholder="Method Version"
              default="1"
              component={SmallEditReadOnlyField}
              style={{marginTop: 8}}
              onChange={formik.handleChange}
            />
           </Box>
           </div>

           <div data-testid="div-6vg9" style={{ flex: 1 }}>
            <Typography data-testid="Typography-5ifo" sx={{ fontSize: 18, fontWeight: 'bold', color: colorConfigs.primary}}>ID</Typography>
            <Box data-testid="Box-ih54" sx={{pl: 1.5}}>
            <Field data-testid="Field-llmt"
              name="id"
              type="text"
              className="form-control"
              // label="Method ID"
              placeholder="Method ID"
              component={SmallEditReadOnlyField}
              style={{marginTop: 8}}
              onChange={formik.handleChange}
            />
           </Box>
           </div>

           <div data-testid="div-anrk" style={{ flex: 1 }}>
            <Typography data-testid="Typography-0bx2" sx={{ fontSize: 18, fontWeight: 'bold', color: colorConfigs.primary }}>Status</Typography>
            <Box data-testid="Box-ttm6" sx={{pl: 1.5}}>
            <Field data-testid="Field-dctl"
              name="status"
              type="text"
              className="form-control"
              // label="Status"
              placeholder="Status"
              component={SmallEditReadOnlyField}
              style={{marginTop: 8}}
              onChange={formik.handleChange}
            />
           </Box>
           </div>

        </div>
      </Box>
    </div>
  );
};

export default MethodDescriptionCard;
