import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { Button } from "@mui/material";
import api from "../../../redux/features/auth/axiosAuthHelper";
import RareButton from "../buttons/RareButton";

const CancelSubscriptionForm = () => {
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validationSchema = () => {
    return Yup.object().shape({});
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {},
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const response = await api.post(
          "payments/cancel-subscription/",
          {},
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );
        if (response.status === 200) {
          setSuccess("Subscription cancelled successfully!");
          setError(""); // Clear any previous errors
        }
      } catch (error: any) {
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
        setSuccess("");
        console.error("Error during checkout:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <hr />
      <h2>Cancel Subscription</h2>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <RareButton
            type="submit"
            variant="cancel"
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              background: "#024c97",
              opacity: 0.6,
              color: "white",
              textTransform: "none",
              fontSize: 16,
            }}
          >
            {loading ? "Processing..." : "Cancel"}
          </RareButton>
        </form>
      </FormikProvider>
      {error && <div data-testid="div-bexp" style={{ color: "red" }}>{error}</div>}
      {success && <div data-testid="div-oiv7" style={{ color: "green" }}>{success}</div>}
    </>
  );
};

export default CancelSubscriptionForm;
