import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import { arraysAreEqual, Point } from "../../../utils/math";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";

export interface ImageProfile {
  profile: number[];
  sample_rows: number;
  sample_cols: number;
}

type ImageProfileSetter = React.Dispatch<
  React.SetStateAction<ImageProfile | undefined>
>;

type StripCornersSetter = React.Dispatch<React.SetStateAction<Point[]>>;

interface RegionSelectorCardProps {
  imageUrl: string;
  homography: number[];
  setImageProfile: ImageProfileSetter;
  setImageCorners: StripCornersSetter;
  imageCorners: Point[];
}

const RegionSelectorCard: React.FC<RegionSelectorCardProps> = ({
  imageUrl,
  homography,
  setImageProfile,
  setImageCorners,
  imageCorners,
}) => {
  const authData = useSelector((state: RootState) => state.auth);

  const [coordinates, setCoordinates] = useState<Point[]>([]);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [loading, setLoading] = useState(false);

  const canculate4thCoordinates = useCallback((coords: Point[]): Point => {
    const xOffset = coords[1][0] - coords[0][0];
    const yOffset = coords[2][1] - coords[0][1];
    const x = coords[2][0] + xOffset;
    const y = coords[1][1] + yOffset;
    return [x, y];
  }, []);

  console.log("RegionSelectorCard:", imageUrl, coordinates);

  // calculate 4th coordinates
  useEffect(() => {
    if (
      (imageCorners.length === 4 || imageCorners.length === 0) &&
      !arraysAreEqual(imageCorners, coordinates)
    ) {
      console.log("Initialize coordinates with:", imageCorners);
      setCoordinates(imageCorners);
    }
  }, [imageCorners]);

  useEffect(() => {
    if (coordinates.length > 0) {
      console.log("New point:", coordinates[coordinates.length - 1]);
      if (coordinates.length === 3) {
        const newPoint: Point = canculate4thCoordinates(coordinates);
        setCoordinates((prevCoordinates) => [...prevCoordinates, newPoint]);
      } else if (coordinates.length === 4) {
        fetchProfile();
      }
    }
  }, [coordinates, canculate4thCoordinates]);

  // Handle click to get coordinates in data units and mark the point
  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    if (imageRef.current && coordinates.length < 4) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCoordinates((prevCoords) => [...prevCoords, [x, y]]);
    }
  };

  const fetchProfile = async () => {
    setLoading(true);
    await getImageProfile();
    setImageCorners(coordinates);
    setLoading(false);
  };

  // Clear all markers
  const clearMarkers = () => {
    setCoordinates([]);
  };

  const getImageProfile = async () => {
    const data = {
      image_url: imageUrl,
      image_corners: coordinates,
      homography,
    };
    try {
      const response = await api.post("calculate-image-profile/", data, {
        headers: {
          Authorization: `Bearer ${authData.access}`,
        },
      });
      setImageProfile(response.data);
    } catch (err) {
      console.error("Failed to fetch imageProfile", err);
    }
  };

  return (
    <>
      {imageUrl && (
        <Box data-testid="Box-k4jt" position="relative" display="inline-block">
          <img
            data-testid="img-saga"
            src={imageUrl}
            alt="Select region"
            ref={imageRef}
            onClick={handleImageClick}
            style={{ maxWidth: "100%", cursor: "crosshair" }}
          />
          {coordinates.map((marker, index) => (
            <Box
              data-testid="Box-94u4"
              key={index}
              sx={{
                position: "absolute",
                left: `${marker[0]}px`,
                top: `${marker[1]}px`,
                width: "5px",
                height: "5px",
                bgcolor: "red",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ))}
          {loading && (
            <Box
              data-testid="Box-84jr"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // Center align
              }}
            >
              <CircularProgress
                data-testid="CircularProgress-kcpz"
                sx={{
                  color: "red",
                }}
                thickness={6}
                size={100}
              />
            </Box>
          )}
        </Box>
      )}

      {imageUrl && (
        <Button
          data-testid="Button-0zu4"
          variant="contained"
          color="secondary"
          onClick={clearMarkers}
          disabled={coordinates.length === 0}
        >
          Reset Corners
        </Button>
      )}
    </>
  );
};

export default RegionSelectorCard;
