
const currentlyInProgressPages = ['feedback', 'phone_management','phone_management.whitelist', 'phone_management.hfactor', 'phone_management.phonesensitivity', 'data_analysis.calibration_management', 'data_analysis.interpreter_management', 'data_analysis.reprocess_data', 'data_analysis.performance_reports']

const checkRoutePermissions = (authData: any, route: any) => {
    if (authData.isAdmin) {
      return route.state !== 'account'; // Admin can see everything except Account (They See admin Panel instead)
    }
    
    // Org Admin - Tier 1 -  route permissions
    if (authData.isOrganisationAdminTier1) {
      if (['admin'].includes(route.state)) return false; // Routes That are Hidden
      if (['feedback', 'phone_setup', 'data_analysis', ...currentlyInProgressPages].includes(route.state)) { // Routes That are Greyed Out as inaccessible for current Tier
        return 'premium';
      }
      return true;
    }

    // Org Admin - Tier 2 - route permissions
    //   Now Allowed Phone Setup
    if (authData.isOrganisationAdminTier2) {
        if (['admin'].includes(route.state)) return false; // Routes That are Hidden
        if (['feedback', 'data_analysis', ...currentlyInProgressPages].includes(route.state)) { // Routes That are Greyed Out as inaccessible for current Tier
          return 'premium';
        }
        return true;
      }
      
    // Org Admin - Tier 3 - route permissions
    //   Now Allowed Phone Setup, feedback and data analysis
      if (authData.isOrganisationAdminTier3) {
        if (['admin'].includes(route.state)) return false; // Routes That are Hidden
        if ([...currentlyInProgressPages].includes(route.state)) { // Routes That are Greyed Out as inaccessible for current Tier
          return 'premium';
        }
        return true;
      }
    
    //   Standard User - Tier 1 - route permissions
    if (authData.isStandardUserTier1) {
      if (['admin', 'account'].includes(route.state)) return false; // Routes That are Hidden
      if (['feedback', 'phone_setup', 'data_analysis', ...currentlyInProgressPages].includes(route.state)) { // Routes That are Greyed Out as inaccessible for current Tier
        return 'premium';
      }
      return true;
    }

    //   Standard User - Tier 2 - route permissions
    //   Now Allowed Phone Setup
    if (authData.isStandardUserTier2) {
        if (['admin', 'account'].includes(route.state)) return false; // Routes That are Hidden
        if (['feedback', 'data_analysis', ...currentlyInProgressPages].includes(route.state)) { // Routes That are Greyed Out as inaccessible for current Tier
            return 'premium';
        }
        return true;
        }

    //   Standard User - Tier 3 - route permissions
    //   Now Allowed Phone Setup, feedback and data analysis
    if (authData.isStandardUserTier3) {
        if (['admin', 'account'].includes(route.state)) return false; // Routes That are Hidden
        if ([...currentlyInProgressPages].includes(route.state)) { // Routes That are Greyed Out as inaccessible for current Tier
          return 'premium';
        }
        return true;
      }
  
    //   Standard User - Tier 0 (Free User) - route permissions
    //   Now Allowed Phone Setup, feedback and data analysis
    if (authData.isFreeUser) {
      if (['admin'].includes(route.state)) return false; // Routes That are Hidden
      if (route.state === 'account.subscription') return true; // Routes That are Greyed Out as inaccessible for current Tier
      return 'premium'; // Disable everything else
    }
  
    return false;
  };
  

export default checkRoutePermissions;