import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { Button } from "@mui/material";
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import RareButton from "../buttons/RareButton";

const CancelMySubscriptionForm = () => {
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const [expiryDate, setExpiryDate] = useState('');

  // Helper function to calculate the expiration date
  const calculateExpiryDate = (monthsToAdd: any) => {
    const currentDate = new Date();
    const expiryDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd));
    return expiryDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Calculate the expiry date when the component mounts or monthsRemaining changes
  useEffect(() => {
    const date = calculateExpiryDate(1);
    setExpiryDate(date);
  }, []);


  const validationSchema = () => {
    return Yup.object().shape({});
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {},
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const response = await api.post(
          "payments/cancel-subscription/",
          {},
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );
        if (response.status === 200) {
          setSuccess("Subscription cancelled successfully!");
          setError(""); // Clear any previous errors
          dispatch(fetchUserProfile());
        }
      } catch (error: any) {
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
        setSuccess("");
        console.error("Error during checkout:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
            {!success &&
            <RareButton 
                disabled={authData.isFreeUser}
                type='submit'
                variant="cancel"
                sx={{textAlign: 'center', marginX: 'auto', textTransform: 'none', fontWeight: 'bold', mt: 1, mb:1, width: '100%'}}>
                  {loading ? "Processing..." : "Confirm Cancel Subscription"}
            </RareButton>
}
        </form>
      </FormikProvider>
      {error && <div data-testid="div-ei90" style={{ color: "red" }}>{error}</div>}
      {success && <div data-testid="div-afn2" style={{ color: "green" }}>{success}</div>}
      {success && <div data-testid="div-cuwr" style={{ color: "green" }}>Your subscription will end on {expiryDate}.</div>}
    </>
  );
};

export default CancelMySubscriptionForm;
