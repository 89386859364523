import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button, Card, Chip, Collapse, Divider, Grid, Grid2, linearProgressClasses, Link, Paper, Tab, Table, TableCell, tableCellClasses, TableRow, Tabs, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import RadialGraph from "../../components/graphs/multiradialgraph";
import LineGraph from "../../components/graphs/linegraph";
import BarGraph from "../../components/graphs/bargraph";
import LastFiveRList from '../../components/common/tables/LastFiveRList';
import LastFiveFList from '../../components/common/tables/LastFiveFList';
import { last5columns, organisationColumns } from '../../components/common/tables/Columns';
import api from "../../redux/features/auth/axiosAuthHelper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { refreshAccessToken } from "../../redux/features/auth/authSlice";
import dayjs from 'dayjs';
import colorConfigs from "../../configs/colorConfigs";
import ResultDetailGraph from "../../components/common/graphs/ResultDetailGraph";
import SubscriptionsTable from "../../components/common/tables/SubscriptionsTable";
import { LatestDCMSessionStorageKey, LatestDCMTableParameterStorageService } from "../../services/sessionStorage";
import LastFiveResultsTable from "../../components/common/tables/LastFiveResultsTable";
import RecentDCMResultsTable from "../../components/common/tables/RecentDCMResultsTable";
import { Field, FormikProvider, useFormik } from "formik";
import fontConfigs from "../../configs/fontConfigs";
import { ResultDetailTableDashboardField, ResultDetailTableField } from "../../components/common/fields/fields";
import dummyresultdata from "../../components/common/graphs/resultdata";
import * as Yup from "yup";
import TestsGraph from "../../components/common/graphs/TestInsightsGraph";
import UsageGraph from "../../components/common/graphs/UsageGraph";
import ResultSourceRadarGraph from "../../components/common/graphs/ResultSourceRadarGraph";
import ResultSourceRadialGraph from "../../components/common/graphs/ResultSourceRadialGraph";
import RareChip from "../../components/common/buttons/RareChip";



type Props = {}

interface LatestDCM {
  id: string;
  organisation_id: number | null;
  user_id: number | null;
  timestamp: string;
  sample_id: string;
  method_id: number | null;
  housing_id: number | null;
  test_configuration: {
    collection_mode: boolean | null;
  }
}

interface LineData {
  score: number | string;
  peak_position: number | string;
  tc_ratio: number | string;
  t_color?: string; // Color for each T-peak line
}

interface GraphData {
  test_lines: LineData[];        // Data for test lines with T-peaks
  cscore: number | string;       // Score for the C-line
  cpeak: number | string;        // Peak position for the C-line
  c_color?: string;               // Color for the C-peak annotation
  profile: any[];                // Mean profile data
  baseline: any[];               // Mean baseline data
  baseline_color: string;        // Color for the mean baseline series
  profile_color: string;         // Color for the mean profile series
  meanProfileVisible: boolean;   // Whether the mean profile/baseline is visible
  baselines: any[];           // Array of frame data (each frame's baseline)
  profiles: any[];         // Array of frame data (each frame's profile)
}


interface SortingState {
  id: string;
  desc?: boolean;
}

interface ColumnVisibilityState {
  [key: string]: boolean;
}

interface FilterState {
  id: string;
  value: string;
}

interface TableState {
  sorting: SortingState[];
  columnVisibility: ColumnVisibilityState;
  columnFilters: FilterState[];
  pagination: { pageSize: number; pageIndex: number };
}

// Initial state
const initialState: TableState = {
  sorting: [],
  columnVisibility: {},
  columnFilters: [],
  pagination: { pageSize: 50, pageIndex: 0 },
};

const HomePage = (props: Props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const [Fcolumns, setFColumns] = useState(last5columns["F"]);
    const [Rcolumns, setrColumns] = useState(last5columns["R"]);
    const [RMData, setRMData] = useState<any>();
    const [RMTotal, setRMTotal] = useState<any>();
    const [FBData, setFBData] = useState<any>();
    const [FMTotal, setFMTotal] = useState<any>();
    const [RData, setRData] = useState<any[]>([]);
    const [FData, setFData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState<any>(null);
    const [lvResult, setLvResult] = useState<any>(null);
    const [value, setValue] = useState('result');
    const [sourceValue, setSourceValue] = useState('radar')
    const [recentRows, setRecentRows] = useState<any>();
    const [latestInvoice, setLatestInvoice] = useState<any>();
    const [invoiceExpired, setInvoiceExpired] = useState<any>('false');
    const [methodCount, setMethodCount] = useState<any>();
    const [housingCount, setHousingCount] = useState<any>();

    const [rows, setRows] = useState<any>(() => {
      return LatestDCMTableParameterStorageService.get(LatestDCMSessionStorageKey.data) || []
    });

    const resultdata = dummyresultdata
    const frameIndex = 0

    const [graphData, setGraphData] = useState<any>({
      test_lines: [],
      cscore: '',
      cpeak: '',
      profile: [''],
      baseline: [''],
      c_color: '',
      baseline_color: '',
      profile_color: '', 
      profiles: [],
      baselines: [],
      meanProfileVisible: false,
  })

    const authData = useSelector((state: RootState) => state.auth);

    const validationSchema = () => {
      return Yup.object().shape({
      });
  }
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
        app_build: '',
        app_version: '',
        device_model: '',
        device_uuid: '',
        full_image: '',
        id: '',
        latitude: '',
        longitude: '',
        notes: '',
        organisation_id: '',
        os_version: '',
        sample_id: '',
        sample_type: '',

        strip_image_paths: [],
        // Test lines
        baseline: '',
        cline_score: '',
        cline_peak_position: '',
        cline_peak_area: '',
        // Lines
        t_lines: [],
        tline_1_score: '',
        tline_1_area: '',
        tline_1_peak_position: '',
        tline_1_ratio: '',
        tline_2_score: '',
        tline_2_area: '',
        tline_2_peak_position: '',
        tline_2_ratio: '',
        profile: '',

        timestamp: '',
        user_id: '',
        uuid: '',
        tc_area_ratio: '',

        // User Data
        result_user_id: '',
        user_first_name: '',
        user_last_name: '',
        user_email: '',
        user_is_active: '',
        user_organisation_id: '',
        user_organisation_name: '',
        user_user_type: '',
        user_subscription_tier: '',

        camera_macro_focus_required: false,
        collection_mode: false,
        control_baseline: false,
        control_baseline_threshold: '',
        control_exposure: false,
        control_line_correction: false,
        flash_required: false,
        frames_to_capture: '',
        initial_h_factor: '',
        polynomial_baseline_order: '',
        profile_alignment: false,
        profile_control_levels: '',
        reader_resolution: [], 
        test_pmf_classifier_file: '',
        max_conformance_probability: '',
        min_conformance_probability: '',        
    },
    validationSchema,
    onSubmit: (values) => {
    },
});

    useEffect(() => {
      // fetch latest result and current result count for the user
      const today = dayjs().format('YYYY-MM-DD')
      const fetchData = () => {
        const today = dayjs().format('YYYY-MM-DD')
        const data: any[] = [];

        api
        .get(`/results/dashboard/latest_non_dcm_result`, {
          headers:{
            Authorization: `Bearer ${authData.access}`,
          },
        })
        .then((res) => {
          console.log("latestresult", res.data)

          const dynamicProfiles: any[] = [];
          const dynamicBaselines: any[] = [];
    
          // Add mean profile and baseline if visible
          dynamicProfiles.push({ data: res.data.test_strips[0]?.mean_profile, color: colorConfigs.result.meanProfile, name: "Mean Profile" });
          dynamicBaselines.push({ data: res.data.test_strips[0]?.baseline, color: colorConfigs.result.meanBaseline, name: "Mean Baseline" });

          setGraphData({
            test_lines: res.data.test_strips[0]?.t_lines,
            cscore: res.data.test_strips[0]?.c_line?.score || 0,
            cpeak: res.data.test_strips[0]?.c_line?.peak_position || 0,
            c_color: res.data.test_strips[0]?.c_color,
            profile: res.data.test_strips[0]?.mean_profile,
            baseline: res.data.test_strips[0]?.baseline,
            baseline_color: colorConfigs.result.meanBaseline,
            profile_color: colorConfigs.result.meanProfile,
            profiles: dynamicProfiles, // Initialize as empty
            baselines: dynamicBaselines, // Initialize as empty
            meanProfileVisible: true
        });
        
        // Test Strips
          formik.setFieldValue('cline_score', res.data.test_strips[0].c_line.score.toFixed(2));
          formik.setFieldValue('cline_peak_position', res.data.test_strips[0].c_line.peak_position.toFixed(2));
          formik.setFieldValue('cline_area', res.data.test_strips[0].c_line.area?.toFixed(2));

          // Setting lines as an array to dynamically render these
          formik.setFieldValue('t_lines', res.data.test_strips[0].t_lines);
          
          // Dynaically sets tline score, peak and tc_ratio
          res.data.test_strips[0].t_lines.map((line:any, index:any, array:any[]) => {
          formik.setFieldValue(`tline_${array.length - index}_score`, parseFloat(line.score).toFixed(2));
          formik.setFieldValue(`tline_${array.length - index}_peak_position`, parseFloat(line.peak_position).toFixed(2));
          formik.setFieldValue(`tline_${array.length - index}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
          formik.setFieldValue(`tline_${array.length - index}_area`, parseFloat(line.area)?.toFixed(2));
          const TCAreaRatio = parseFloat(line.area) / res.data.test_strips[0].c_line.area
          formik.setFieldValue(`tline_${array.length - index}_tc_area_ratio`, TCAreaRatio.toFixed(2));
          })
          
          setIsLoading(false);
        })
        .catch((err) =>{
          console.log(err);
        })
      
      api
      .get('/results/?limit=10&dcm=true', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("latestresult", res.data)
        setRows(res.data.results)

      }).catch((err) =>{
        console.log(err);
      })

      api
      .get('/results/views', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("recentresults", res.data)
        setRecentRows(res.data.results)

      }).catch((err) =>{
        console.log(err);
      })

      api
      .get('/payments/invoices/', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("latestinvoice", res.data)
        setLatestInvoice(res.data.results[0].current_period_end)
        let invoicedatediff = new Date().getTime() - latestInvoice
          if (invoicedatediff > 0) {
          setInvoiceExpired('true')
        }

      }).catch((err) =>{
        console.log(err);
      })

      api
      .get('/methods/?limit=1', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("method count: ", res.data.count)
        setMethodCount(res.data.count)
      }).catch((err) => {
        console.log(err);
      })

      api
      .get('/housing/?limit=1', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("housing count: ", res.data.count)
        setHousingCount(res.data.count)
      }).catch((err) => {
        console.log(err);
      })
      };

      fetchData()
      dispatch(refreshAccessToken());
    }, [authData.access]);

    const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const handleSourceTabsChange = (event: React.SyntheticEvent, newValue: string) => {
      setSourceValue(newValue);
      console.log(sourceValue)
    };

    const graphDataLength = graphData.test_lines.length ? graphData.test_lines.length : 1

    return authData.isFreeUser ?  (<Navigate to="/account/subscription"/>) : (
      <div data-testid="div-ci9o">
        <Box data-testid="Box-5moc" sx={{display:"flex", justifyContent:"center", alignItems:"center", p:3}}>
          <Grid2 direction='column' alignItems='center' container spacing={1} sx={{ width: 580, pr:1}}>
            <Grid2 size={12}>
              <Card data-testid="Card-j4ln" sx={{width:'100%', height: 530}}>
                {value === 'result' ? 
                  <Typography data-testid="Typography-usiu" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_red, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                    Latest Result
                  </Typography> 
                : value === 'dcm' ? 
                  <Typography data-testid="Typography-pdgn" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_red, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                    Latest DCM
                  </Typography> 
                : null }
                <Tabs data-testid="Tabs-wodg"
                  value={value}
                  onChange={handleTabsChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab data-testid="Tab-ycj0" value="result" label="Result" />
                  <Tab data-testid="Tab-93v2" value="dcm" label="Data Collection" />
                </Tabs>
              {value == 'result' ?
              <>
              <Collapse in={graphData.test_lines.length > 0 } sx={{height:'100%'}}>
                <Box data-testid="Box-irp7" sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <ResultDetailGraph graphdata={graphData} showGraph={true}/> 
                </Box>
                <FormikProvider value={formik}>

                <Table data-testid="Table-fbsj" sx={{width: '100%', height: 'auto' }}>
                  <tbody>
                    <TableRow data-testid="TableRow-am19" sx={{ p: 0, m: 0}}>
                        <TableCell data-testid="TableCell-gak8" sx={{ p: 0, m: 0}}>
                            <Field data-testid="Field-ssc1" name={`method_id`} type="text" className="form-control" label="Method" component={ResultDetailTableDashboardField} color={'white'} />
                        </TableCell>
                        <TableCell data-testid="TableCell-dvhn" sx={{ p: 0, m: 0 }}>
                            <Field data-testid="Field-3did" name={`housing_id`} type="text" className="form-control" label="Housing" component={ResultDetailTableDashboardField} color={'white'} />                                            
                        </TableCell>
                    </TableRow>
                </tbody>
              </Table>
              </FormikProvider>
              </Collapse>
              </>
                : value == 'dcm' ?
              <>
                <RecentDCMResultsTable 
                  authData={authData}
                  rows={rows} />
              </>
                : null
              } 
                  
                <Card data-testid="Card-3uvr">

                </Card>             
              </Card>
            </Grid2>
            <Grid2 size={12}>
              <Card data-testid="Card-dv4c" sx={{height: 270, alignItems: 'center', justifyContent:"center"}}>
              <Typography data-testid="Typography-tyex" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_red, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Recently viewed
                </Typography>
                <LastFiveResultsTable 
                authData={authData}
                rows={recentRows} />  
              </Card>
            </Grid2>
          </Grid2>
          <Grid2 direction='column' alignItems='center' container spacing={1} sx={{ width: 580, pr:1}}>
            <Grid2 size={12} alignItems='center'>
              <Card data-testid="Card-j4r0" sx={{height: 400, alignItems: 'center', justifyContent:"center"}}>
              <Typography data-testid="Typography-wyo9" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_red, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Tests
                </Typography>
              <TestsGraph/>  
            </Card>
            </Grid2>
            <Grid2 size={12} alignItems='center'>
              <Card data-testid="Card-hw4k" sx={{height: 400, alignItems: 'center', justifyContent:"center"}}>
              <Typography data-testid="Typography-wmls" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_red, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Result Source
                </Typography>
                <Tabs data-testid="Tabs-5cz8"
                value={sourceValue}
                onChange={handleSourceTabsChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab data-testid="Tab-6ni9" value="radar" label="Radar" />
                <Tab data-testid="Tab-4tx1" value="radial" label="Radial" />
              </Tabs>
              {sourceValue == 'radar' ?
                <ResultSourceRadarGraph/>
                : sourceValue == 'radial' ?
                <ResultSourceRadialGraph/>
                : null }
              </Card>
            </Grid2>
          </Grid2>
          <Grid2 direction='column' alignItems='center' container spacing={1} sx={{ width: 580}}>
            <Grid2 size={12} alignItems='center'>
              <Card data-testid="Card-hkp5" sx={{height: 560, alignItems: 'center', justifyContent:"center"}} >
                <Typography data-testid="Typography-s94k" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_red, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Usage
                </Typography>
                <Table data-testid="Table-3sci" sx={{ width: '100%', height: 'auto', [`& .${tableCellClasses.root}`]: { borderBottom: "none"}, borderCollapse: 'separate', borderSpacing: '0 0px' }}>
                  <TableRow data-testid="TableRow-stsd">
                    <TableCell data-testid="TableCell-5rsl">
                    <Typography data-testid="Typography-yl7l" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, borderRadius: 1}}>
                      Tier
                    </Typography>
                    </TableCell>
                    <TableCell data-testid="TableCell-9m94"/>
                    <TableCell data-testid="TableCell-c63a"> 
                      <Typography data-testid="Typography-0h1u" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        <RareChip variant="success">{authData.tier}</RareChip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow data-testid="TableRow-wpsn">
                  <TableCell data-testid="TableCell-ljv1">
                    <Typography data-testid="Typography-nd5b" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                      Next Invoice
                    </Typography>
                  </TableCell>
                  <TableCell data-testid="TableCell-40l8"/>
                  <TableCell data-testid="TableCell-tkmc"> 
                    <Typography data-testid="Typography-m4bw" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                      <RareChip variant={invoiceExpired == 'true' ? 'cancel' : latestInvoice? "success" : 'cancel'}>{latestInvoice? latestInvoice : 'N/A'}</RareChip>
                    </Typography>
                  </TableCell>
                  </TableRow>
                  <TableRow data-testid="TableRow-3q62">
                    <TableCell data-testid="TableCell-4s9s">
                      <Typography data-testid="Typography-aj5p" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Housings
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="TableCell-sdvg"/>
                    <TableCell data-testid="TableCell-h7f2"> 
                      <Typography data-testid="Typography-61tf" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        <RareChip variant='success'>{housingCount}</RareChip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow data-testid="TableRow-0emo">
                    <TableCell data-testid="TableCell-kr5l">
                      <Typography data-testid="Typography-m90i" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Methods
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="TableCell-wdx8"/>
                    <TableCell data-testid="TableCell-nn8y"> 
                      <Typography data-testid="Typography-ebwv" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        <RareChip variant='success'>{methodCount}</RareChip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow data-testid="TableRow-jpd7">
                    <TableCell data-testid="TableCell-u6w0">
                      <Typography data-testid="Typography-2yny" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Users
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="TableCell-zzdm"/>
                    <TableCell data-testid="TableCell-16e4"> 
                      <Typography data-testid="Typography-0ix8" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        <RareChip variant='success'>1/3</RareChip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow data-testid="TableRow-21l8">
                    <TableCell data-testid="TableCell-61wj">
                      <Typography data-testid="Typography-r33n" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Processing Units
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="TableCell-3eky"/>
                    <TableCell data-testid="TableCell-z29q"> 
                      <Typography data-testid="Typography-c9rq" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#FFA500", paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        <RareChip variant='success'>285/300</RareChip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow data-testid="TableRow-rwti">
                    <TableCell data-testid="TableCell-6qh4">
                      <Typography data-testid="Typography-86hg" sx={{fontSize: fontConfigs.detailPage.headings.label, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Data Capacity
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="TableCell-rg0y"/>
                    <TableCell data-testid="TableCell-asi2"> 
                      <Typography data-testid="Typography-h8v7" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        <RareChip variant='success'>145MB/450MB</RareChip>
                      </Typography> 
                    </TableCell>
                  </TableRow>
                  <TableRow data-testid="TableRow-dj3u">
                    <TableCell data-testid="TableCell-q0fd">
                      <Typography data-testid="Typography-prgj" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_grey, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        No. of Results
                      </Typography>
                    </TableCell>
                    <TableCell data-testid="TableCell-er51"/>
                    <TableCell data-testid="TableCell-u0rz"> 
                      <Typography data-testid="Typography-ph6a" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        <RareChip variant='success'>230</RareChip>
                      </Typography>
                    </TableCell>
                  </TableRow>

                </Table>

              </Card>
            </Grid2>
            <Grid2 size={12}>
              <Card data-testid="Card-8jj2" sx={{height: 240}}>
              <Typography data-testid="Typography-aq65" sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: colorConfigs.dashboard.dark_red, pt: 1, margin: 'auto', borderRadius: 1}}>
                  Usage Table
                </Typography>
                <UsageGraph/>
              </Card>
            </Grid2>
          </Grid2>
        </Box>
        {/* <div data-testid="div-j638" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Grid container spacing={1} sx={{height: '100px', width: '70%'}}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Card data-testid="Card-7o20" sx={{mt: '1%', pt:'2%', height: '100px', background: colorConfigs.sub_banner.secondary_sub}}>
              <Tooltip data-testid="Tooltip-312o" title="Upgrade your subscription!">
                <Link data-testid="Link-zc23" 
                  href="/admin" 
                  underline="none" 
                  sx={{
                    color: 'white',
                    fontSize: '24px',
                    pt: '1.5%',
                  }}>
                  Subscription Tier
                </Link>
              </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card data-testid="Card-azy9" sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip data-testid="Tooltip-pvpr" title="Go to your Admin panel">
                  <Link data-testid="Link-p7ww" 
                    href="/admin" 
                    underline="none" 
                    sx={{
                      color: 'white',
                      fontSize: '24px',
                      pt: '1.5%',
                    }}>
                    Organisation_Name
                  </Link>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card data-testid="Card-hqnj" sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip data-testid="Tooltip-v4cr" title="Add more users">
                    <Link data-testid="Link-d6qn" 
                      href="/admin" 
                      underline="none" 
                      sx={{
                        color: 'white',
                        fontSize: '24px',
                        pt: '1.5%',
                      }}>
                      Users/MaxUsers
                    </Link>
                  </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card data-testid="Card-5u48" sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip data-testid="Tooltip-jj0s" title="Increase your maximum monthly results">
                  <Link data-testid="Link-vgnu" 
                    href="/admin" 
                    underline="none" 
                    sx={{
                      color: 'white',
                      fontSize: '24px',
                      pt: '1.5%',
                    }}>
                    Results/MaxResults
                  </Link>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                  <Card data-testid="Card-jbky" sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography data-testid="Typography-fk73" variant="body2" color="text.secondary" align="center">
                          Latest results
                    </Typography>
                    <LastFiveRList
                        columns={Rcolumns}
                        data={RData}
                        isLoading={isLoading}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card data-testid="Card-ggjt" sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography data-testid="Typography-7rp0" variant="body2" color="text.secondary" align="center" sx={{p:1, fontSize: "20px"}}>
                        Today's Results
                    </Typography>
                      <RadialGraph total={RMTotal} data={RMData}/>
                  </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card data-testid="Card-k1k1" sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography data-testid="Typography-qh7e" variant="body2" color="text.secondary" align="center">
                          Users
                    </Typography>
                    <LastFiveRList
                        columns={Rcolumns}
                        data={RData}
                        isLoading={isLoading}
                      />
                  </Card>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card data-testid="Card-k6gh" sx={{height:'35vh', background: colorConfigs.sub_banner.secondary_sub}}>
                <LineGraph data={[]}/>
              </Card>
            </Grid>
          </Grid>
        </div> */}
      </div>
   ) 
};

export default HomePage;