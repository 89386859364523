import SignIn from "../../components/common/Signin";


type Props = {}

const LoginPage = (props: Props) => {
  return (
    <div data-testid="div-oy7u" style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <SignIn />
    </div>
  );
};

export default LoginPage;