import React, { useEffect, useState } from "react";
import { LinearProgress, Typography, Box } from "@mui/material";

type ProgressBarProps = {
  initialDateTime: string; // in UTC format, e.g., "2024-12-31T13:30:51.654206Z"
  totalCount: number;
  successful: number;
  failed: number;
};

type TimeUnit = {
  unit: "seconds" | "minutes" | "hours";
  value: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  initialDateTime,
  totalCount,
  successful,
  failed,
}) => {
  const [progress, setProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState<TimeUnit>({
    unit: "seconds",
    value: 0,
  });

  const convertSecondsToUnit = (seconds: number): TimeUnit => {
    if (seconds <= 90) {
      return {
        unit: "seconds",
        value: Math.round(seconds),
      };
    } else if (seconds < 5400) {
      return {
        unit: "minutes",
        value: Math.round(seconds / 60),
      };
    } else {
      return {
        unit: "hours",
        value: Math.round(seconds / 3600),
      };
    }
  };

  const calculateProgress = (
    initial: string,
    total: number,
    completedCount: number,
  ) => {
    if (total <= 0) {
      setProgress(100);
      setTimeRemaining({
        unit: "seconds",
        value: 0,
      });
    }
    if (completedCount <= 0) {
      setProgress(0);
      setTimeRemaining({
        unit: "seconds",
        value: Infinity,
      });
    }
    const initialDate = new Date(initial);

    const currentTime = new Date();
    const elapsedTime = (currentTime.getTime() - initialDate.getTime()) / 1000; // seconds

    const totalTime = (elapsedTime * total) / completedCount;
    const timeRemainingInSeconds = totalTime - elapsedTime;
    const progressPercentage =
      totalTime > 0 ? (elapsedTime / totalTime) * 100 : 0;

    setProgress(progressPercentage);
    setTimeRemaining(convertSecondsToUnit(timeRemainingInSeconds));
  };

  useEffect(() => {
    calculateProgress(initialDateTime, totalCount, successful + failed);
  }, [initialDateTime, totalCount, successful, failed]);

  return (
    <Box data-testid="Box-2h58" sx={{ width: "100%", textAlign: "center" }}>
      <Typography data-testid="Typography-kdpt" variant="body1" sx={{ mb: 1 }}>
        Time Remaining:{" "}
        {timeRemaining.value !== Infinity
          ? `${timeRemaining.value} ${timeRemaining.unit}`
          : "-"}
      </Typography>
      <LinearProgress data-testid="LinearProgress-csak" variant="determinate" value={progress} />
    </Box>
  );
};

export default ProgressBar;
