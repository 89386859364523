import React from 'react';
import { Box, LinearProgress, Typography, Collapse } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';

interface DownloadProgressBoxProps {
  totalCountLoaded: number;
  totalCount: number;
}

const DownloadProgressBar: React.FC<DownloadProgressBoxProps> = ({ totalCountLoaded, totalCount }) => {

  const progress = totalCount > 0 ? (totalCountLoaded / totalCount) * 100 : 0;

  return (
    <Collapse in={totalCount > 0} timeout={200}>
    <Box data-testid="Box-0g7y" sx={{ width: '100%', height: '30px' }}>
          <LinearProgress data-testid="LinearProgress-6oeh" variant="determinate" value={progress}
            sx={{
              backgroundColor: '#88BC72', // Background color (track)
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#88BC72', // Progress bar color
              },
            }}
            />
          {totalCountLoaded !== totalCount ? (
            <Typography data-testid="Typography-zekp"
              variant="body2"
              sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}
            >
              {totalCountLoaded} / {totalCount} <FileDownloadOutlinedIcon />
            </Typography>
          ) : (
            <Typography data-testid="Typography-bie2"
              variant="body2"
              sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}
            >
              {totalCountLoaded} / {totalCount} <CloudDoneOutlinedIcon color="success" />
            </Typography>
          )}
    </Box>
    </Collapse>
  );
};

export default DownloadProgressBar;
