

import { Card, Grid, Box, Button, Typography, InputLabel, Table, TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Switch, Dialog, DialogContent, CircularProgress, Collapse, IconButton, Tooltip, AppBar, Toolbar, DialogActions, Slide, LinearProgress } from '@mui/material';
import React, { Component, useEffect, useState, useRef } from 'react'
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
//import PostService from "../../../services/post.service";
import { Navigate, Link as routerLink } from "react-router-dom";
// import { TCRatioField, TextDetailField, FormVisualResultsField, ImageField, TScoreField, CScoreField, CPeakField, NotesField, TPeakField  } from '../fields/resultDetailFields';
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs, { Dayjs } from 'dayjs';
import colorConfigs from '../../../configs/colorConfigs';
import ResultDetailGraph from '../graphs/ResultDetailGraph';
import { useTheme } from '@mui/material/styles';
import { ResultDetailEditField, TCRatioField, TScoreField, CScoreField, CPeakField, TPeakField, NotesField, TextAdornmentReadOnlyField, TextAdornmentNoStatusReadOnlyField, ReadOnlySwitch, ResultDetailTableField } from '../fields/fields';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Slider from "react-slick";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import sizeConfigs from '../../../configs/sizeConfigs';
import fontConfigs from '../../../configs/fontConfigs';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ResultDetailAccordion from '../accordions/ResultDetailAccordion';
import PMFTimeline from '../timelines/PMFTimeline';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import ResultDetailDCMHomography from './ResultDetailDCMHomography';

interface ResultDetailDCMCardProps {
  handleBackButton: () => void;
  popup: any;
  result_id: any;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const ResultDetailDCMCard: React.FC<ResultDetailDCMCardProps> = ({ handleBackButton, popup, result_id}) => {

    const [user, setUser] = useState<any>("")
    const authData = useSelector((state: RootState) => state.auth);
    const [result, setResult] = useState<any>(null)
    const [expanded, setExpanded] = useState(''); // Default to 'resultInfo' open on render
    const [selectedTab, setSelectedTab] = useState(0);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [selectedStrip, setSelectedStrip] = useState({
        baseline: [],
        c_line: {
            score: null,
            peak_position: null
        },
        lines: [
            {t_color: '',}
        ],
        c_color: '',
        profile_color: '',
        baseline_color: '',
        main_image: "",
        mean_profile: [],
        pmf_story: [
            {
                baseline: [],
                full_image: "",
                homography: [],
                lux: null,
                orientation: [],
                pmf_status: null,
                profile: [],
                strip_image: "",
                strip_statuses: [],
                timestamp: ""
            }
        ]
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedStory, setSelectedStory] = useState<any>(null);

    const handleDialogOpen = (story: any, index: any) => {
        setSelectedStory(story);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedStory(null);
    };


    const handleStripTabChange = (event: any, newValue: any) => {
        setSelectedTab(newValue);
    };


    //Setting for Carousel
    const sliderRef = useRef<Slider>(null); // Reference for the Slider
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)

    const handleThumbnailClick = (index: number) => {
        setSelectedImageIndex(index);
        sliderRef.current?.slickGoTo(index); // Navigate to the selected slide
    };

    const handlePrev = () => {
        const newIndex = selectedImageIndex === 0 ? pmfStories.length - 1 : selectedImageIndex - 1;
        setSelectedImageIndex(newIndex);
        sliderRef.current?.slickGoTo(newIndex); // Navigate to the previous slide
    };

    const handleNext = () => {
        const newIndex = (selectedImageIndex + 1) % pmfStories.length;
        setSelectedImageIndex(newIndex);
        sliderRef.current?.slickGoTo(newIndex); // Navigate to the next slide
    };
    const settings = {
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Show 3 slides
        slidesToScroll: 1,
        centerMode: true, // Center the middle slide
        focusOnSelect: true,
        beforeChange: (_: any, next: any) => setSelectedImageIndex(next),
        arrows: false,
    };

    const [pmfStories, setPmfStories] = useState<any[]>([])
    
    // Download image function
    const handleOpenInNewTab = (imageUrl: any) => {
        window.open(imageUrl, '_blank');
    };
    

    useEffect(() => {
        // When selectedStrip or selectedTab changes, reset carousel to first image
        sliderRef.current?.slickGoTo(0); // Navigate the carousel to the first slide
    }, [selectedStrip, selectedTab]);


    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    // Fetch Result for Feedback
    useEffect(() => {
        const fetchResult = async () => { 
            try {
                const url = `results/${result_id}/data_collector_mode`
          
                const response = await api.get(url, {
                    headers: {
                        'Authorization': `Bearer ${authData.access}`
                    }
                });
                setResult(response.data);
                console.log("result", response.data)

                try {
                    const url = `users/${response?.data?.user_id}`
              
                    const res = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    setUser(res.data);
                    console.log("user", res.data)

                    try {
                        const url = `results/views/${result_id}`
                  
                        const res = await api.put(url, null, {
                            headers: {
                                'Authorization': `Bearer ${authData.access}`
                            }
                        });
                        console.log("Updated recently viewed result: ", result_id)
                    } catch (error) {
                        console.error('Error fetching result', error);
                    }

                } catch (error) {
                    console.log(error)
                    console.log(`Cannot find User with id ${result.user_id}`)
                }

            } catch (error) {
                console.error('Error fetching result', error);
            }
          };
            fetchResult()    
        }, [result_id])

    const theme = useTheme();


    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            app_build: '',
            app_version: '',
            device_model: '',
            device_uuid: '',
            full_image: '',
            id: '',
            latitude: '',
            longitude: '',
            notes: '',
            organisation_id: '',
            os_version: '',
            sample_id: '',
            sample_type: '',

            strip_image_paths: [],
            // Test lines
            baseline: '',
            cline_score: '',
            cline_peak_position: '',
            cline_peak_area: '',
            // Lines
            lines: [],
            tline_1_score: '',
            tline_1_area: '',
            tline_1_peak_position: '',
            tline_1_ratio: '',
            tline_2_score: '',
            tline_2_area: '',
            tline_2_peak_position: '',
            tline_2_ratio: '',
            profile: '',

            timestamp: '',
            user_id: '',
            uuid: '',

            // User Data
            result_user_id: '',
            user_first_name: '',
            user_last_name: '',
            user_email: '',
            user_is_active: '',
            user_organisation_id: '',
            user_organisation_name: '',
            user_user_type: '',
            user_subscription_tier: '',

            camera_macro_focus_required: false,
            collection_mode: false,
            control_baseline: false,
            control_baseline_threshold: '',
            control_exposure: false,
            control_line_correction: false,
            flash_required: false,
            frames_to_capture: '',
            pmf_initial_h_scale_factor: '',
            polynomial_baseline_order: '',
            profile_alignment: false,
            profile_control_levels: '',
            reader_resolution: [], 
            test_pmf_classifier_file: '',
            test_pmf_max_conformance_probability: '',
            test_pmf_min_conformance_probability: '',  
            
            selected_story_date: '',
            selected_story_time: '',
            selected_story_difference: '',
            selected_story_homography: '',
            selected_story_lux: '',
            selected_story_orientation: '',
            selected_story_pmf_status: '',

        },
        validationSchema,
        onSubmit: (values) => {
        },
    });
    


    useEffect(() => {
        if (result) {
                console.log("Result Data", result)

                setPmfStories(result.pmf_story)

                formik.setFieldValue('app_build', result.app_build);
                formik.setFieldValue('app_version', result.app_version);
                formik.setFieldValue('device_model', result.device_model);
                formik.setFieldValue('device_uuid', result.device_uuid);
                formik.setFieldValue('id', result.id);
                formik.setFieldValue('latitude', result.latitude);
                formik.setFieldValue('longitude', result.longitude);
                formik.setFieldValue('notes', result.notes);
                formik.setFieldValue('organisation_id', result.organisation_id);
                formik.setFieldValue('os_version', result.os_version);
                formik.setFieldValue('sample_id', result.sample_id);
                formik.setFieldValue('sample_type', result.sample_type);
                formik.setFieldValue('method_id', result.method_id);
                formik.setFieldValue('housing_id', result.housing_id);


                formik.setFieldValue('camera_macro_focus_required', result.test_configuration.camera_macro_focus_required);
                formik.setFieldValue('collection_mode', result.test_configuration.collection_mode);
                formik.setFieldValue('control_baseline', result.test_configuration.control_baseline);
                formik.setFieldValue('control_baseline_threshold', result.test_configuration.control_baseline_threshold);
                formik.setFieldValue('control_exposure', result.test_configuration.control_exposure);
                formik.setFieldValue('control_line_correction', result.test_configuration.control_line_correction);
                formik.setFieldValue('flash_required', result.test_configuration.flash_required);
                formik.setFieldValue('frames_to_capture', result.test_configuration.frames_to_capture);
                formik.setFieldValue('pmf_initial_h_scale_factor', result.test_configuration.pmf_initial_h_scale_factor);
                formik.setFieldValue('polynomial_baseline_order', result.test_configuration.polynomial_baseline_order);
                formik.setFieldValue('profile_alignment', result.test_configuration.profile_alignment);
                formik.setFieldValue('profile_control_levels', result.test_configuration.profile_control_levels);
                formik.setFieldValue('reader_resolution', result.test_configuration.reader_resolution);
                formik.setFieldValue('test_pmf_max_conformance_probability', result.test_configuration.test_pmf_max_conformance_probability);
                formik.setFieldValue('test_pmf_min_conformance_probability', result.test_configuration.test_pmf_min_conformance_probability);

                formik.setFieldValue('timestamp', dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                formik.setFieldValue('user_id', result.user_id)
                formik.setFieldValue('uuid', result.uuid)
    
                // Set User Data
                if (user) {
                    formik.setFieldValue('result_user_id', user.id);
                    formik.setFieldValue('user_first_name', user.first_name);
                    formik.setFieldValue('user_last_name', user.last_name);
                    formik.setFieldValue('user_email', user.email);
                    formik.setFieldValue('user_is_active', user.is_active);
                    formik.setFieldValue('user_organisation_name', user.organisation_name);
                    formik.setFieldValue('user_user_type', user.user_type);
                    formik.setFieldValue('user_subscription_tier', user.subscription_tier);
                }
                setTimeout(() => { 
                    setExpanded('resultInfo')  
                }, 1800);
                setTimeout(() => { 
                    setShowThumbnails(true)  
                }, 2000);
            }
    }, [user, result]);

    useEffect(() => {
      if (selectedStory) {
        formik.setFieldValue('selected_story_homography', `[${selectedStory.homography}]` || '');
        formik.setFieldValue('selected_story_lux',  selectedStory.lux || '');
        formik.setFieldValue('selected_story_orientation',  `[${selectedStory.orientation}]` || '');
        formik.setFieldValue('selected_story_pmf_status',  selectedStory.pmf_status || '');

        formik.setFieldValue('selected_story_date', dayjs(selectedStory.timestamp).format('MMM D, YYYY') || '');
        formik.setFieldValue('selected_story_time', dayjs(selectedStory.timestamp).format('hh:mm:ss.SSS A') || '');
        formik.setFieldValue('selected_story_difference', (() => {
            const firstTimestamp = dayjs(pmfStories[0]?.timestamp);
            const currentTimestamp = dayjs(selectedStory.timestamp);
            const diffMs = currentTimestamp.diff(firstTimestamp); // Difference in milliseconds
            const diffSeconds = diffMs / 1000; // Convert to seconds
            if (diffSeconds < 60) {
                return `+ ${diffSeconds.toFixed(3)}s`;
            } else {
                const minutes = Math.floor(diffSeconds / 60);
                const seconds = (diffSeconds % 60).toFixed(3);
                return `+ ${minutes}m ${seconds}s`;
            }
        })() || '');
      }
    },[selectedStory])

    const tabs = ['Timeline View', 'Thumbnail View']

    return !result_id ? (
        <Navigate to={"/results"} />
    ) : !result ? (
        <Box data-testid="Box-kpsz" sx={{ width: "100%" }}>
        <LinearProgress data-testid="LinearProgress-0kb2" />
        </Box>    
    ) : (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{height: '100%' }}>

            <Grid 
                container 
                sx={{ 
                    height: '100%',
                    minHeight: '100%',
                    display: 'flex', 
                    overflowY:{ md: 'auto', lg: 'hidden'}
                }}
                >            

                <ResultDetailAccordion result={result} authData={authData} expanded={expanded} setExpanded={setExpanded} user={user} formik={formik}/>

            {/* Right Panel */}
            <Grid 
                item 
                xs={12} 
                sm={12} 
                md={9} 
                lg={9} 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%',
                }}
            >
                    {/* Top Box For Tabs*/}
                    <Grid 
                        item 
                        sx={{ 
                        flex: '0 1 5%',
                        borderBottom: "solid #eee 1px", 
                        width: '100%',
                        background: 'rgba(1, 1, 1, 0.02)'
                        }}
                    >
                    <Box data-testid="Box-ve7o" sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box data-testid="Box-i6vx" sx={{ width: '100%' }}>
                        <Tabs data-testid="Tabs-rbxa"
                            value={selectedTab}
                            onChange={handleStripTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="Scrollable tabs for strips"
                            indicatorColor="secondary"
                            sx={{
                            width: '100%',
                            overflowX: 'auto',
                            maxWidth: '100%',
                            minHeight: sizeConfigs.detailPage.appbar.height,
                            maxHeight: sizeConfigs.detailPage.appbar.height,
                            '& .MuiTabs-scrollButtons': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginX: 1,
                                '&.Mui-disabled': {
                                display: 'none', 
                                },
                                '&:not(.Mui-disabled)': {
                                display: 'flex',
                                },
                                '& .MuiIconButton-root': {
                                fontSize: '2rem',
                                fontWeight: 'bold',
                                '&:hover': {
                                    color: colorConfigs.primary,
                                },
                                },
                                '& .MuiSvgIcon-root': {
                                color: '#fff',
                                background: colorConfigs.primary,
                                borderRadius: '50%',
                                fontSize: '2rem',
                                '&:hover': {
                                    background: colorConfigs.sidebar.activeBg,
                                },
                                },
                            },
                            '& .MuiTabs-indicator': {
                                // backgroundColor: colorConfigs.primary,
                                // backgroundColor: 'transparent',
                            },
                            }}
                        >
                            {tabs.map((tab, index) => (
                            <Tab data-testid="Tab-fihf" 
                                key={index} 
                                label={tab}
                                disableRipple
                                sx={{
                                textTransform: 'none',
                                fontSize: fontConfigs.detailPage.headings.title,
                                fontWeight: 'bold',
                                minWidth: {sm: '60px', md: '70px', lg: '100px'},
                                minHeight: sizeConfigs.detailPage.appbar.height,
                                maxHeight: sizeConfigs.detailPage.appbar.height,
                                // color: ,
                                '&:hover': {
                                    color: colorConfigs.primary,
                                },
                                '&.Mui-selected': {
                                    color: colorConfigs.primary,
                                    fontWeight: 'bold',
                                },
                                }} 
                            />
                            ))}
                        </Tabs>
                        </Box>
                    </Box>
                    </Grid>

                    {/* Middle Container for Bottom Boxes */}
                    <Grid 
                        container 
                        sx={{ 
                        flex: '1 1 auto',
                        display: 'flex', 
                        flexDirection: 'row',
                        width: '100%',
                        height: '100%',
                        minHeight: sizeConfigs.detailPage.dialogContent.timeline,
                        maxHeight: sizeConfigs.detailPage.dialogContent.timeline,
                        }}
                    >
                { !showThumbnails && <Box data-testid="Box-jdtl" sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress data-testid="CircularProgress-uj0l" /> </Box>}

                {(selectedTab === 0 && pmfStories.length > 0) &&
                    <Box data-testid="Box-3o9r"
                        sx={{
                            height: '100%',
                            width: '100%',
                            paddingX: 3,
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        <Box data-testid="Box-vtx7"
                            sx={{
                                position: 'absolute',
                                top: "5vh",
                                left: 0,
                                width: '100%',
                                zIndex: 0,
                                p: 3, 
                                pb: 10,
                                textAlign: 'start',
                                pointerEvents: 'none',
                                background: "rgba(0, 0, 0, 0.02)"
                            }}
                        >
                            <Typography data-testid="Typography-0yvc" sx={{ fontWeight: 'bold', fontSize: '30px' }}>Date</Typography>
                            <Typography data-testid="Typography-fthg" sx={{ pl: 3, fontSize: '25px' }}>
                                {dayjs(pmfStories[0].timestamp).format('MMM D, YYYY')}
                            </Typography>
                        </Box>

                        <Collapse in={showThumbnails}>
                            <Box data-testid="Box-fdnp"
                                sx={{
                                    m: 'auto',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    overflowY: 'auto',
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                <PMFTimeline pmfStories={pmfStories} handleDialogOpen={handleDialogOpen} />
                            </Box>
                        </Collapse>
                    </Box>


                }

                {selectedTab === 1 &&
                <Box data-testid="Box-fgq0" sx={{                        
                    minHeight: sizeConfigs.detailPage.dialogContent.timeline,
                    maxHeight: sizeConfigs.detailPage.dialogContent.timeline,
                    maxWidth: '100%',
                    overflowY: 'auto', // Allow vertical scrolling
                    }}>
                <Box data-testid="Box-qfii" sx={{ 
                    flex: 1, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    maxHeight: '50%', 
                    padding: "1em"
                }}>
                    <Box data-testid="Box-2061" sx={{
                        width: "100%",
                        maxWidth: '600px',
                        p: 2,
                        margin: 'auto',
                    }}>
                        <Slider data-testid="Slider-31e6" {...settings} ref={sliderRef}>
                            {pmfStories.map((pmfStory, index) => (
                                <Tooltip data-testid="Tooltip-kuks" key={index} arrow placement="top" title={(
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px", color: "#fff" }}>
                                        <span data-testid="span-ya4h">{`Image ${index + 1}`}</span>
                                        <IconButton data-testid="IconButton-k0va" 
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                window.open(pmfStory.image_url, '_blank');
                                            }}
                                            size="small"
                                            sx={{ padding: 0 }}
                                        >
                                            <AspectRatioOutlinedIcon fontSize="small" sx={{ ml: 1, color: "#fff" }} />
                                        </IconButton>
                                    </div>
                                )}>
                                    <div data-testid="div-9hvb" 
                                        key={index} 
                                        style={{ padding: "0 10px" }} 
                                        onClick={() => index === selectedImageIndex && handleDialogOpen(pmfStory, index)} // Only opens dialog for selected image
                                    >
                                        <img data-testid="img-ndub" src={pmfStory.image_url} alt={`Slide ${index}`} 
                                            style={{ 
                                                width: "100%", 
                                                maxHeight: "500px",
                                                maxWidth: "500px",
                                                transition: "all 0.3s ease-in-out", 
                                                transform: index === selectedImageIndex ? "scale(1.1)" : "scale(0.8)", 
                                                objectFit: "contain",
                                                cursor: index === selectedImageIndex ? 'pointer' : 'default' // Pointer only for center image
                                            }} 
                                        />
                                    </div>
                                </Tooltip>
                            ))}
                        </Slider>

                        {/* Icons below selected image */}
                        <Box data-testid="Box-2z91" sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                            <IconButton data-testid="IconButton-nrl8" 
                                onClick={() => handleOpenInNewTab(pmfStories[selectedImageIndex].image_url)}
                                sx={{
                                    // color: colorConfigs.primary,
                                    '&:hover': { color: colorConfigs.primary },
                                }}
                            >
                                <DownloadIcon fontSize="large" />
                            </IconButton>
                            <IconButton data-testid="IconButton-j6vy" 
                                onClick={() => handleDialogOpen(pmfStories[selectedImageIndex], selectedImageIndex)}
                                sx={{
                                    // color: colorConfigs.primary,
                                    '&:hover': { color: colorConfigs.primary },
                                }}
                            >
                                <ZoomInIcon fontSize="large" />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>


                    <Collapse in={showThumbnails}>
                    <Box data-testid="Box-b8of" sx={{ 
                        flex: 1, 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        gap: 2, 
                        padding: 4, 
                        justifyContent: 'center', 
                        overflowY: 'auto',
                        overflowX: 'auto',
                        maxWidth: '100%',
                    }}>
                    {pmfStories.map((pmfStory, i) => (
                        <Tooltip data-testid="Tooltip-eqyq" 
                            key={i} 
                            arrow
                            placement="bottom"
                            title={(
                                <div style={{ display: "flex", alignItems: "center", gap: "5px", color: "#fff", background: colorConfigs.tables.headBg }}>
                                    <span data-testid="span-bzl6">{`Image ${i + 1}`}</span>
                                    <IconButton data-testid="IconButton-84s4" 
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            window.open(pmfStory.image_url, '_blank');
                                        }}
                                        size="small"
                                        style={{ padding: 0 }}
                                    >
                                        <AspectRatioOutlinedIcon fontSize="small" sx={{ ml: 1, color: "#fff" }} />
                                    </IconButton>
                                </div>
                            )}
                        >
                            <button key={i} onClick={() => handleThumbnailClick(i)} 
                                    style={{ border: 'none', background: "none", padding: 0 }}>
                                <img data-testid="img-i3t8" src={pmfStory.image_url} alt={`Thumbnail ${i}`} width="60px" height="60px" 
                                    style={{ opacity: i === selectedImageIndex ? 1 : 0.5, transition: 'opacity 0.3s' }} />
                                <Typography data-testid="Typography-19a8" fontSize={'small'}>{i + 1}</Typography>
                            </button>
                        </Tooltip>
                    ))}
                </Box>
                </Collapse>
                </Box>
                }
                </Grid>
            </Grid>

            
            {/* Dialog */}
            {(selectedStory && result) && (
            <ResultDetailDCMHomography authData={authData} housing_id={result.housing_id} handleDialogClose={handleDialogClose} selectedStory={selectedStory} pmfStories={pmfStories} dialogOpen={dialogOpen} formik={formik} setSelectedStory={setSelectedStory} />
            )}


        </Grid>


            </form>
        </FormikProvider>
    )
}

export default ResultDetailDCMCard;