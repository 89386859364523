import { Card, Container, createTheme, ThemeProvider } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Chart from "react-apexcharts";
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useLocation } from "react-router-dom";
import colorConfigs from '../../../configs/colorConfigs';
import assets from '../../../assets';


interface GraphData {
  control_line: any;
  points: any;
  strip_info: any;
  aspectRatioHeight: string;
  aspectRatioWidth: string;
}

const CartesianGrid: React.FC<{ graphdata: any, create: any, aspectRatioWidth: string, aspectRatioHeight: string  }> = ({ create, graphdata, aspectRatioHeight, aspectRatioWidth }) => {
  const [apexChartData, setApexChartData] = useState<any[]>([]);
  const [graphText, setGraphText] = useState<any>(() => {
    if (create) {
      return ""
    }
    return "Loading Point Model..."
  })


  useEffect(() => {
    if (!graphdata) return;
  
    // Extract the first array of coordinates from each point in graphdata.points
    const extractedSeries = graphdata.points.map((point: any) => ({
      x: point[0][0],  // x-coordinate
      y: point[0][1],  // y-coordinate
    }));
  
    // Set the extracted series data
    setApexChartData(extractedSeries);
  }, [graphdata]);

  

  const chartData: ApexOptions = {
    noData: {
      text: graphText,
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '20px',
      }
    },
    colors: ['#ff6347', '#1e90ff'],
    chart: {
      animations:{
        enabled: false,
      },
      id: "scatter",
      type: "scatter",
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    xaxis: {
      type: 'numeric',
      min: Math.min(...apexChartData.map(point => point.x)) - 100, 
      max:  Math.max(...apexChartData.map(point => point.x)) + 100, 
      title: {
        text: '',
        style: {
        }
      },
      labels: {
        style: {
        }
      }
    },
    yaxis: {
      reversed: true,
      min: Math.min(...apexChartData.map(point => point.y)) - 50,
      max: Math.max(...apexChartData.map(point => point.y)) + 50,  // Add some padding
      title: {
        text: '',
        style: {}
      },
      labels: {
        style: {}
      }
    },
    grid: {
      // borderColor: '#1D1D1D'
    },
    fill: {
      type: 'image',
      opacity: 1,
      image: {
          src: [assets.images.blueCross],
          width: 10,
          height: 10,
      },
    },
    markers: {
      size: 5,
      strokeWidth: 0,
    },
    series: [
      {
        name: "",
        data: apexChartData,
        color: colorConfigs.result.meanBaseline
      },
    ]
  }

  return  (
      <Card data-testid="Card-so3a" sx={{ p: 0, m:0, paddingTop: 1, display: 'flex', justifyContent: "center", alignItems: "center", width: {aspectRatioWidth}, height: {aspectRatioHeight}, boxShadow: 2 }} >
      <ThemeProvider theme={createTheme()}>
          <Container data-testid="Container-yhvy" component="main" maxWidth="xl" sx={{display: 'flex', justifyContent: "center", alignItems: "center", paddingTop: 1}}>
            <ReactApexChart height={aspectRatioHeight} width={aspectRatioWidth} type="scatter" options={chartData} series={chartData.series} />
          </Container>
        </ThemeProvider>
      </Card>
  )

}

export default CartesianGrid;
