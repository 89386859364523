import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import SignInDialog from '../../components/common/SignInDialog';
import api from '../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import colorConfigs from '../../configs/colorConfigs';

type Props = {}

const PhoneSensitivityPage = (props: Props) => {
  
const authData = useSelector((state: RootState) => state.auth);


  return authData.isAuthenticated == false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated == true && authData.isFreeUser ? (
    <Navigate to="/account/subscription"/>
    ) : authData.isAuthenticated == true && authData.isFreeUser === false ? (
      <div data-testid="div-fj3y" style={{ overflowX: "hidden", maxHeight: `100%`, minHeight: `100%`, maxWidth: `100%`, minWidth: `100%`, margin: "auto"}}>

      <div data-testid="div-brp3" style={{ display: "flex", height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`,}}>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{m:0, height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`,}}
        >
          <Grid item xs={3} sx={{m:0, p:0, maxWidth: `100%`, minWidth: `100%`}}>
            <Box data-testid="Box-444d" sx={{ m:0, p:3, height: "100%", overflowY: "auto", marginRight: 0, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `93vw`, minWidth: `100%` }}>

            <h1>Phone Sensitivity Page</h1>

            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
) : (null);
};
export default PhoneSensitivityPage;
