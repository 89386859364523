import { Chip, Box, Button, FormControlLabel, Collapse, Tabs, Tab, MenuItem, TextField, Checkbox, Alert, AlertTitle} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import * as Yup from "yup";
import { FormikProvider, useFormik } from 'formik';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { InvoiceSessionStorageKey, InvoiceTableParameterStorageService } from '../../../services/sessionStorage';
import colorConfigs from '../../../configs/colorConfigs';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

dayjs.extend(utc);
dayjs.extend(timezone);
const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
dayjs.tz.setDefault(browserTimezone);

  
interface FilterBarProps {
  setIsLoading: (data: boolean) => void;
  handleRemoveAllFilters: () => void;
  fetchData: (query?: any) => any;
  }


const InvoicesFilterBar: React.FC<FilterBarProps> = ({setIsLoading, handleRemoveAllFilters, fetchData}) => {

    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const [saveCustomFilter, setSaveCustomFilter] = useState<boolean>(false);
    const errorRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<string | false>('panel1'); 
    const [activeTab, setActiveTab] = useState<number | false>(0);
    const [quickFilter, setQuickFilter] = useState<string>("")
    const [filterCriteriaOptions, setFilterCriteriaOptions] = useState<any[]>([]);
    const [tempValue, setTempValue] = useState<any>(''); // State to hold temporary input value
    const [tempFromDate, setTempFromDate] = useState<string | null>(null);
    const [tempToDate, setTempToDate] = useState<string | null>(null);
    
    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    useEffect(() => {
      if (errors && errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }, [errors]);

    const loadInitialValues = () => {
      // Default initial values
      const initialValues = {
        // id: "", // ADD
        // invoice_number: "", // ADD
        // status: "", // ADD
        // from_date: dayjs().format(),
        // to_date: dayjs().format(),
        // email: "",
        // user_type: "",
        // organisation: "",
        // organisation_name: "",
        created: "",
        // last_login: "",
        // is_active: "",

        selectedFilter: "",
        new_filter: "",
        filter_to_delete: "",
        skip: "",
        limit: "",
        user_filters: [],
      };
    
      // Load stored options from session storage
      const storedOptions = InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.filterCriteria) || [];
    
      // Merge stored options into initial values if their values are not empty
      storedOptions.forEach((option: any) => {
        if (option.value !== '' && option.name in initialValues) {
          initialValues[option.name as keyof typeof initialValues] = option.value;
        }
      });
    
      return initialValues;
    };

    const formik = useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: loadInitialValues(),
      validationSchema,
      onSubmit: async (values) => {
        setErrors(null);
        setIsLoading(true)
        const isUpdatingFilters = Boolean(values.new_filter || values.filter_to_delete);

        const createdString = values.created || '';        
        const startDateMatch = createdString.match(/From(\d{4}-\d{2}-\d{2})/);
        const endDateMatch = createdString.match(/To(\d{4}-\d{2}-\d{2})/);
        const startDate = startDateMatch?.[1] || '';
        const endDate = endDateMatch?.[1] || '';   

        // Handle either updating users custom filters or queriying results
        const data = {
              // id: values.id,
              // invoice_number: values.invoice_number,
              // status: values.status,
              start_date: startDate,
              end_date: endDate,
              // skip: values.skip,
              // limit: values.limit,
          }

        console.log(data)
      
        // Concatinates search query parameters - Filter out empty values
        const queryParams = Object.entries(data).reduce((acc, [key, value]) => {
          if (value) { // This will ignore undefined values, effectively filtering out empty fields
            acc.set(key, value);
          }
          return acc;
        }, new URLSearchParams());
      
        // Generate the query string by concatinating any fields that have been entered
        const queryString = queryParams.toString();
      
        if (!isUpdatingFilters) {  // Fetch Results with querystring
          const data = await fetchData(queryString)
          if (data.length < 1) {
            setErrors("There are no results matching your search.");
          } else {
            setTimeout(() => {
              setExpanded(false)
            }, 500);
          }
        } else { // Update users profile with updated result_filters
          try {
              api.patch(`profile/`, data, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }
              })
            .then(res => {
              handleRemoveAllFilters()
              setErrors(null);
              setIsLoading(false)
              if (searchRef.current) {
                searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
              }
              dispatch(fetchUserProfile());
              formik.setFieldValue('new_filter', "")
              formik.setFieldValue('filter_to_delete', "")
            })

        } catch(error: any) {
            setIsLoading(false)
            if (quickFilter) {
                handleClearAll()
            }
            if (error?.response?.status === 400) {
                const firstErrorItem = Object.keys(error.response.data)[0];
                setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
                const errorText = `Unknown error fetching feedback data`;
                setErrors(errorText);
                console.error(errorText, error);
              }
            }
          }
      },
  });
  

  // Handles switching accordian tabs from Qucik Filters to Advanced Filters
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setErrors('')
    if (newValue === activeTab) {
      setExpanded(false);
      setActiveTab(false);
    } else {
      setExpanded(false);
      setTimeout(() => {
        setExpanded('panel1');
        setActiveTab(newValue);
      }, 100);
    }
  };

  // Handlers for Custom Filters
  // Handles setting custom filter buttons CSS
  const doFilterValuesMatch = (formikValues: any, filter: any) => {
    if (!filter) return false;
    const keysToCheck = Object.keys(filter);
    return keysToCheck.every(key => formikValues[key] === filter[key]);
  }
  // Handles deleting all users custom filters
  const handleDeleteAllCustomFilters = () => {
    formik.setFieldValue('new_filter', "delete_all_custom_filters")
    formik.submitForm();
  }
  // Handles deleting a specific custom filter
  const handleDeleteCustomFilter = (filter: any) => {
    formik.setFieldValue('filter_to_delete', filter.name)
    formik.submitForm();
  }
  // Handles saving custom filters
  const handleCustomFilterSave = () => {
    setSaveCustomFilter(false)
    formik.submitForm();
  }
  
    // Sets a list of search criteria options
    useEffect(() => {
      const options: any[] = generateFilterCriteriaOptions(formik.values);
      setFilterCriteriaOptions(options);
      InvoiceTableParameterStorageService.set(InvoiceSessionStorageKey.filterCriteria, options)
    }, [formik.values]);
    
    // Generates a list of search criteria options based
    const generateFilterCriteriaOptions = (formikValues: typeof formik.values): any[] => {
      const omitFromFilter = ['limit', 'skip', 'new_filter', 'user_filters', 'from_date', 'filter_to_delete', 'to_date', 'criteria', 'selectedFilter', 'display'];
      // Select Input options
      
      // List of field types
      const dateTypeInputs = ['created']

      const filterCriteriaOptions: any[] = [];
      
      for (const key in formikValues) {
        // Skip keys that are in omitFromFilter or that end with '_display'
        if (omitFromFilter.includes(key) || key.endsWith('_display')) {
            continue;
        }
  
          const value = formikValues[key as keyof typeof formikValues];
          let type = 'text';
          let options = undefined;
          let label = key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

          // Check for date type
          if (dateTypeInputs.includes(key)) {
            type = 'date';
          }

          // Special case for 'organisation' field
          if (key === 'created') {
            label = 'Date';
          }
  
          filterCriteriaOptions.push({
              name: key as keyof typeof formik.values,
              label,
              value,
              type,
              options
          });
      }
  
      return filterCriteriaOptions;
  };
    
  
    // Handlers for Advanced Filters Changing
    const handleFilterChange = (event: any) => {
      const selectedOption = filterCriteriaOptions.find(option => option.name === event.target.value);
      if (!selectedOption) return; // Handle the case where selectedOption is undefined
      formik.setFieldValue('selectedFilter', selectedOption.name);
  
      // Reset or set temp values based on the selected option type
      if (selectedOption.type === 'date') {
        const currentDateValue = formik.values[selectedOption.name as keyof typeof formik.values];
  
          if (typeof currentDateValue === 'string') {
              const [fromDate, toDate] = currentDateValue.split('-').map((dateStr: string) => 
                  dateStr.replace(/From|To/g, '').trim()
              );
              setTempFromDate(fromDate ? dayjs(fromDate).toISOString() : null);
              setTempToDate(toDate ? dayjs(toDate).toISOString() : null);
          } else {
              setTempFromDate(null);
              setTempToDate(null);
          }
      } else {
          const currentValue = formik.values[selectedOption.name as keyof typeof formik.values];
          setTempValue(currentValue ? currentValue : '');
          setTempFromDate(null);
          setTempToDate(null);
      }
  };

  const handleAddFilter = () => {
    if (!selectedFilter) return;

    if (selectedFilter.type === 'date' && tempFromDate && tempToDate) {
        // Handle date filters
        const formattedFrom = dayjs(tempFromDate).startOf('day').format(); // Format to start of the day
        const formattedTo = dayjs(tempToDate).endOf('day').format(); // Format to end of the day
        const value = `From${formattedFrom}-To${formattedTo}`;
        formik.setFieldValue(selectedFilter.name, value);
    } else {
        // Handle other types of filters (e.g., text, select, checkbox)
        formik.setFieldValue(selectedFilter.name, tempValue); // tempValue should hold the value for non-date filters
    }
};

  const selectedFilter = filterCriteriaOptions.find(option => option.name === formik.values.selectedFilter);


  // Handlers for Quick Filter Functions
  const handleChipDelete = (filterName:any) => {
    formik.setFieldValue(filterName, '');
    formik.setFieldValue(`${filterName}_display`, ''); // Clear the display value as well
  };
  
  const handleClearAll = () => {
    filterCriteriaOptions.forEach(option => {
        formik.setFieldValue(option.name, '');
        formik.setFieldValue(`${option.name}_display`, ''); // Clear all display values
    });
  };

  const formatDateRange = (from: any, to: any) => {
    return `From${from.format()}-To${to.format()}`;
  };

  const getToday = () => {
      const from = dayjs().tz().startOf('day');
      const to = dayjs().tz().endOf('day');
      return formatDateRange(from, to);
  };

  const getYesterday = () => {
      const from = dayjs().tz().subtract(1, 'day').startOf('day');
      const to = dayjs().tz().subtract(1, 'day').endOf('day');
      return formatDateRange(from, to);
  };

  const getLast7Days = () => {
      const from = dayjs().tz().subtract(6, 'day').startOf('day');
      const to = dayjs().tz().endOf('day');
      return formatDateRange(from, to);
  };

  const getMonthToDate = () => {
      const from = dayjs().tz().startOf('month').startOf('day');
      const to = dayjs().tz().endOf('day');
      return formatDateRange(from, to);
  };

  const getQuarterToDate = () => {
    const currentMonth = dayjs().month();
    const startOfQuarter = dayjs().subtract(currentMonth % 3, 'month').startOf('month').startOf('day');
    const endOfQuarter = dayjs().endOf('day');
  
    return formatDateRange(startOfQuarter, endOfQuarter);
  };

  const getYearToDate = () => {
      const from = dayjs().tz().startOf('year').startOf('day');
      const to = dayjs().tz().endOf('day');
      return formatDateRange(from, to);
  };

return (
  <Box data-testid="Box-soek" ref={searchRef}>
    <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
            <div data-testid="div-ruck">
              <div data-testid="div-de5m">
              <Accordion data-testid="Accordion-xnqo"
                expanded={Boolean(expanded)}
                onChange={(event, isExpanded) => setExpanded(isExpanded ? 'panel1' : false)}
                sx={{
                  borderTopRightRadius: 2,
                  borderTopLeftRadius: 2,
                  border: 0,
                  background: '#f9f9f9',
                  boxShadow: 0,
                  paddingBottom:0,
                  marginBottom: 0,
                  '&.Mui-expanded': {
                    paddingBottom: 0,
                    marginBottom: 0,
                  },
                }}
                slotProps={{ transition: { timeout: 800 } }}
              >
      <AccordionSummary data-testid="AccordionSummary-oux6"
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          height: '40px',
          minHeight: '40px',
          width: '100%',
          background: '#f9f9f9',
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          margin: 0,
          alignItems: 'center',
          borderTopRightRadius: 2,
          borderTopLeftRadius: 2,
          justifyContent: 'center',
          '&.Mui-expanded': {
            minHeight: '40px',
            margin: 0,
          },
        }}
      >
  <Tabs data-testid="Tabs-yluc"
    value={activeTab}
    onChange={handleTabChange}
    TabIndicatorProps={{
      style: {
        display: expanded ? '' : 'none',
        background: '#fff',
        padding: 0,
        bottom: 10
      },
    }}
    sx={{
      width: '100%',
      height: '100%',
      minHeight: '45px',
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
      background: colorConfigs.tables.headBg,
      color: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <Tab data-testid="Tab-henv"
      label="Quick Filters"
      sx={{
        ml: {lg: '25%', xl: '28%'},
        textTransform: 'none',
        borderTopLeftRadius: 5,
        color: '#fff',
        height: '100%',
        fontWeight: 'bold',
        minHeight: '40px',
        width: 'auto',
        '&.Mui-selected': {
          color: '#fff',
        },
      }}
      disableRipple
    />
    <Tab data-testid="Tab-vqdu"
      label="Advanced Filters"
      sx={{
        ml: {lg: '25%', xl: '28%'},
        textTransform: 'none',
        borderTopRightRadius: 5,
        color: '#fff',
        height: '100%',
        fontWeight: 'bold',
        minHeight: '40px',
        width: 'auto',
        '&.Mui-selected': {
          color: '#fff',
        },
      }}
      disableRipple
    />
  </Tabs>

      </AccordionSummary>
      {activeTab === 0 && (
        <AccordionDetails
          sx={{
            height: '100%',
            background: '#f9f9f9',
            p: 0,
            paddingTop: 0,
            paddingBottom:0,
          }}
        >
      <Collapse in={!!errors} timeout="auto">
          <Alert data-testid="Alert-mcmw" ref={errorRef} severity="error">
              <AlertTitle data-testid="AlertTitle-mv54">Error</AlertTitle>
              {errors}
          </Alert>
        </Collapse>
      <Box data-testid="Box-u38e" sx={{
                display: 'flex', 
                flexDirection: 'column',
                p: 0.7,
                pt: errors ? 0.7 : 1,
                pb: 0
                }}>

      <Box data-testid="Box-t056" sx={{
          height: '100%',
          maxWidth: '100%', 
          display: 'flex', 
          alignItems: 'center',
          gap: 0.7, 
          overflowX: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none', // Internet Explorer 10+
          scrollbarWidth: 'none', // Firefox
          mb: 0.7
          }}>   
          <Button data-testid="Button-sjzj"
              fullWidth
              variant="outlined"
              id="demo-customized-button-label"
              disableElevation
              disableRipple
              sx={{ 
                  flex: 1,
                  minWidth: "10em",
                  maxWidth: "10em",
                  textTransform: 'none', 
                  fontWeight: 'bold', 
                  background: 'transparent',
                  '&:hover': {
                    transform: 'scale(1)',
                  },
              }}  
          >
              Date Created
          </Button>  
          <Chip
              label={"Today"}
              onClick={() => {
                formik.resetForm()
                setQuickFilter("Today")
                formik.setFieldValue('created', getToday());
                formik.submitForm()
            }}                  
              sx={{  
                flex: 1,
                minWidth: "fit-content", 
                background: formik.values.created === getToday() ? colorConfigs.tables.headBg : colorConfigs.buttons.bg,
                color: '#fff', 
                fontWeight: 'bold',
                fontSize: "16px",
                p:0.5,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  background: colorConfigs.tables.headBg
                },
                "& .MuiChip-deleteIcon": {
                  ml: 0.5,
                  color: "#eee",
                  fontSize: 'small',
                  '&:hover': {
                    color: "red",
                  }
                },
              }}
          />

          <Chip
              label={"Yesterday"}
              onClick={() => {
                formik.resetForm()
                setQuickFilter("Yesterday")
                formik.setFieldValue('created', getYesterday());
                formik.submitForm()
            }}             
              sx={{  
                flex: 1,
                minWidth: "fit-content", 
                background: formik.values.created === getYesterday() ? colorConfigs.tables.headBg : colorConfigs.buttons.bg,
                color: '#fff', 
                fontWeight: 'bold',
                fontSize: "16px",
                p:0.5,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  background: colorConfigs.tables.headBg
                },
                "& .MuiChip-deleteIcon": {
                  ml: 0.5,
                  color: "#eee",
                  fontSize: 'small',
                  '&:hover': {
                    color: "red",
                  }
                },
              }}
          />

          <Chip
              label={"Last 7 Days"}
              onClick={() => {
                formik.resetForm()
                setQuickFilter("Last 7 Days")
                formik.setFieldValue('created', getLast7Days());
                formik.submitForm()
            }}           
              sx={{  
                flex: 1,
                minWidth: "fit-content", 
                background: formik.values.created === getLast7Days() ? colorConfigs.tables.headBg : colorConfigs.buttons.bg,
                color: '#fff', 
                fontWeight: 'bold',
                fontSize: "16px",
                p:0.5,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  background: colorConfigs.tables.headBg
                },
                "& .MuiChip-deleteIcon": {
                  ml: 0.5,
                  color: "#eee",
                  fontSize: 'small',
                  '&:hover': {
                    color: "red",
                  }
                },
              }}
          />

          <Chip
              label={"Month To Date"}
              onClick={() => {
                formik.resetForm()
                setQuickFilter("Month To Date")
                formik.setFieldValue('created', getMonthToDate());
                formik.submitForm()
            }}       
              sx={{ 
                flex: 1,
                minWidth: "fit-content",  
                background: formik.values.created === getMonthToDate() ? colorConfigs.tables.headBg : colorConfigs.buttons.bg,
                color: '#fff', 
                fontWeight: 'bold',
                fontSize: "16px",
                p:0.5,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  background: colorConfigs.tables.headBg
                },
                "& .MuiChip-deleteIcon": {
                  ml: 0.5,
                  color: "#eee",
                  fontSize: 'small',
                  '&:hover': {
                    color: "red",
                  }
                },
              }}
          />  

          <Chip
              label={"Quarter To Date"}
              onClick={() => {
                formik.resetForm()
                setQuickFilter("Quarter To Date")
                formik.setFieldValue('created', getQuarterToDate());
                formik.submitForm()
            }}   
              sx={{ 
                flex: 1,
                minWidth: "fit-content", 
                background: formik.values.created === getQuarterToDate() ? colorConfigs.tables.headBg : colorConfigs.buttons.bg,
                color: '#fff', 
                fontWeight: 'bold',
                fontSize: "16px",
                p:0.5,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  background: colorConfigs.tables.headBg
                },
                "& .MuiChip-deleteIcon": {
                  ml: 0.5,
                  color: "#eee",
                  fontSize: 'small',
                  '&:hover': {
                    color: "red",
                  }
                },
              }}
          />  

          <Chip
              label={"Year to Date"}
              onClick={() => {
                formik.resetForm()
                setQuickFilter("Year to Date")
                formik.setFieldValue('created', getYearToDate());
                formik.submitForm()
            }}
              sx={{  
                flex: 1,
                minWidth: "fit-content",
                background: formik.values.created === getYearToDate() ? colorConfigs.tables.headBg : colorConfigs.buttons.bg,
                color: '#fff', 
                fontWeight: 'bold',
                fontSize: "16px",
                p:0.5,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  background: colorConfigs.tables.headBg
                },
                "& .MuiChip-deleteIcon": {
                  ml: 0.5,
                  color: "#eee",
                  fontSize: 'small',
                  '&:hover': {
                    color: "red",
                  }
                },
              }}
          />  
          </Box>

      </Box>


        </AccordionDetails>
      )}
      {activeTab === 1 && (
        <AccordionDetails
          sx={{
            height: '100%',
            background: '#f9f9f9',
            p:0,
          }}
        >
        <Collapse in={!!errors} timeout="auto">
          <Alert data-testid="Alert-wrxr" ref={errorRef} severity="error">
              <AlertTitle data-testid="AlertTitle-24j1">Error</AlertTitle>
              {errors}
          </Alert>
        </Collapse>

        {/* New Filter Bar */}
        <Box data-testid="Box-m5iw" sx={{
          height: '100%',
          maxWidth: '100%', 
          gap: 0.7, 
          overflowX: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none', // Internet Explorer 10+
          scrollbarWidth: 'none', // Firefox
          paddingX: 1,
          paddingTop: 0,
          paddingBottom: 0.5,
          }}>
        
        <Box data-testid="Box-ankm" sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 0.7
        }}>

        {/* Selection Box */}
        <Box data-testid="Box-obcr" className="form-group" 
              sx={{
                minWidth: '20%',
              }}
              >
          <div data-testid="div-lk0v" className="form-group" style={{marginBottom: "8px" }}>
            <TextField data-testid="TextField-fan1" 
                name="criteria" 
                margin="normal"
                className="form-control" 
                label="Filter By"
                value={formik.values.selectedFilter}
                onChange={handleFilterChange}
                select
                fullWidth
                sx={{ mb: 0, mt: 2 }}
            >
                  <MenuItem data-testid="MenuItem-9ul5" value="">
                      Select a filter
                  </MenuItem>
                {filterCriteriaOptions.map((option, index) => (
                    <MenuItem data-testid="MenuItem-7x6f" key={option.name} value={option.name}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
          </div>
        </Box>
        <ArrowRightOutlinedIcon 
            fontSize='large' 
            sx={{ 
              color: "#5A77B7",
              mt: 1,
              visibility: selectedFilter ? 'visible' : 'hidden',
            }} 
          />

      {/* Input Box */}
      <Box data-testid="Box-prfc" sx={{
            flexGrow: 1,
            alignItems: 'center',
            // ml: 2,
            mt: selectedFilter && selectedFilter.type === 'select' ? 0 : 1,
          }}>
      {selectedFilter && (
          <Box data-testid="Box-7sv4" sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5
          }}>
            {/* TEXT */}
              {selectedFilter.type === 'text' && (
                <div data-testid="div-6k8s" className="form-group" style={{minWidth: '40%' }}>
                  <TextField data-testid="TextField-skja" 
                      name={selectedFilter.name}
                      type="text"
                      className="form-control"
                      label={selectedFilter.label}
                      placeholder={`Enter ${selectedFilter.label}`}
                      value={tempValue as string }  // Bind to tempValue
                      onChange={(e: any) => setTempValue(e.target.value)}  // Update tempValue
                      fullWidth
                      sx={{ mb: 0 }}
                  />
                </div>
              )}
            {/* NUMBER */}
              {selectedFilter.type === 'number' && (
                <div data-testid="div-bxs7" className="form-group" style={{minWidth: '40%' }}>
                  <TextField data-testid="TextField-jq2v" 
                      name={selectedFilter.name}
                      type="number"
                      className="form-control"
                      label={selectedFilter.label}
                      placeholder={`Enter ${selectedFilter.label}`}
                      value={tempValue as string }  // Bind to tempValue
                      onChange={(e: any) => setTempValue(e.target.value)}  // Update tempValue
                      fullWidth
                      sx={{ mb: 0 }}
                  />
                </div>
              )}
            {/* SELECT */}
              {selectedFilter.type === 'select' && (
                  <div data-testid="div-w1zt" className="form-group" style={{marginBottom: "8px", minWidth: '40%' }}>
                    <TextField data-testid="TextField-uaia" 
                        name={selectedFilter.name}
                        margin="normal"
                        className="form-control" 
                        label={selectedFilter.label}
                        value={tempValue as string}
                        onChange={(e: any) => setTempValue(e.target.value)}
                        select
                        fullWidth
                        sx={{ mb: 0 }}
                    >
                        {selectedFilter.options?.map((option: any, index: any) => (
                            <MenuItem data-testid="MenuItem-qr4s" key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                  </div>
              )}
                                  
              {/* CHECKBOX */}
              {selectedFilter.type === 'checkbox' && (
                  <div data-testid="div-icl8" className="form-group">
                      <FormControlLabel
                          control={
                              <Checkbox
                                  checked={tempValue === 'Active' ? true : false}
                                  onChange={(e: any) => setTempValue(e.target.checked ? 'Active' : "InActive")}
                                  name={selectedFilter.name}
                                  sx={{ mb: 0 }}
                              />
                          }
                          label={selectedFilter.label}
                      />
                  </div>
              )}
              {/* DATE */}
              {selectedFilter.type === 'date' && (
                  <Box data-testid="Box-o97w" style={{ display: "flex", gap:8}}>
                      <div data-testid="div-yyax" className="form-group">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker data-testid="DatePicker-ljvw"
                                  label="From"
                                  sx={{ mb: 0 }}
                                  value={tempFromDate ? dayjs(tempFromDate) : null}
                                  onChange={(newValue) => setTempFromDate(newValue ? dayjs(newValue).toISOString() : null)}
                              />
                          </LocalizationProvider>
                      </div>
                      <div data-testid="div-s3t4" className="form-group" >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker data-testid="DatePicker-0769"
                                  label="To"
                                  sx={{ mb: 0 }}
                                  value={tempToDate ? dayjs(tempToDate) : null}
                                  onChange={(newValue) => setTempToDate(newValue ? dayjs(newValue).toISOString() : null)}
                              />
                          </LocalizationProvider>
                      </div>
                  </Box>
              )}

            <Chip
                label={"Add"}
                onClick={(() => {setQuickFilter(""); handleAddFilter()})}
                onDelete={() => {setQuickFilter(""); handleAddFilter()}}
                deleteIcon={<AddOutlinedIcon sx={{color: "#fff"}} />}
                sx={{  
                  mb: selectedFilter.type === 'select' ? -1 : 0,
                  mr: 0.7,
                  background: colorConfigs.buttons.bg, 
                  color: '#fff', 
                  fontWeight: 'bold',
                  transition: 'background 1s ease-in-out',                  
                  '&:hover': {
                    background: "linear-gradient(45deg, #4A66A1, #6A84BC)"
                  },
                  "& .MuiChip-deleteIcon": {
                    color: "#fff",
                    '&:hover': {
                      color: "#fff",
                    }
                  },
                }}
            />

            </Box>
            )}
           {!selectedFilter && (
            <Box data-testid="Box-eib2" sx={{ display: "flex", gap:2}}>
              <Button data-testid="Button-idxo"
                  fullWidth
                  variant="outlined"
                  id="demo-customized-button-label"
                  disableElevation
                  disableRipple
                  sx={{ 
                      mb:0,
                      minHeight: '40px',
                      minWidth: "15em",
                      maxWidth: "15em",
                      width: "fit-content",
                      textTransform: 'none', 
                      fontWeight: 'bold', 
                      display: 'flex',
                      justifyContent: 'space-between',
                      background: 'transparent',
                      '&:hover': {
                      },
                  }}  
            >
              <KeyboardBackspaceOutlinedIcon />
                SELECT A FILTER
                <div data-testid="div-ullh"></div>
              </Button> 
            </Box>
           )}
            </Box>

            {/* search box */}
            <Box data-testid="Box-bjx3" sx={{display: "flex", gap: 0.7, mt: 1}}>
              <Button data-testid="Button-gs4i"
                  fullWidth
                  type="submit"
                  onClick={(() => {setQuickFilter("")})}
                  variant="contained"
                  id="demo-customized-button3"
                  disableElevation
                  autoFocus
                  endIcon={<SearchOutlinedIcon />}
                  sx={{  
                    minWidth: "11.5em",
                    width: "fit-content",
                    height: "40px",
                    textTransform: 'none', 
                    transition: 'transform 0.3s ease', 
                    fontWeight: 'bold', 
                  }}      

                  >
                  Search
              </Button>   
          </Box>
        </Box>


          </Box>  

        </AccordionDetails>
      )}
    </Accordion>
    {(filterCriteriaOptions.some(option => formik.values[option.name as keyof typeof formik.values])) && (
  <Collapse in={!!(filterCriteriaOptions.some(option => formik.values[option.name as keyof typeof formik.values]))} timeout="auto">
    <Box data-testid="Box-0lty"
      sx={{
        paddingX: 0.7,
        paddingTop: 1,
        paddingBottom: 0.7,
        display: (filterCriteriaOptions.some(option => formik.values[option.name as keyof typeof formik.values])) ? 'flex' : 'none',
        flexDirection: expanded && activeTab === 1 ? 'row' : 'row',
        justifyContent: expanded && activeTab === 1 ? 'space-between' : '',
        transition: 'padding-top 0.2 ease-in-out, padding-bottom 0.2 ease-in-out',
        background: '#f9f9f9',

      }}
    >

      {/* Chips */}
      <Box data-testid="Box-whxs" sx={{display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center'}}>
        {filterCriteriaOptions.map((option: any) => {
          const rawValue = formik.values[option.name as keyof typeof formik.values];
          // If the option is of type 'date' and follows the expected format, parse and format it
          const displayValue =
            option.type === 'date' && typeof rawValue === 'string' && rawValue.includes('T')
              ? rawValue.replace(/From(\d{4}-\d{2}-\d{2})T.*To(\d{4}-\d{2}-\d{2})T.*/, 'From $1 To $2')
              : rawValue;
        
          return displayValue ? (
            <Chip
              key={option.name}
              onClick={() => { setExpanded('panel1'); setActiveTab(1); }}
              label={
                <span data-testid="span-4qgs">
                  <span data-testid="span-y2m5" style={{ fontWeight: 'bold'}}>{option.label}</span>
                  {`: `}
                  <span data-testid="span-76gp" style={{ fontWeight: 'bold', color: "#4A66A1" }}>{displayValue}</span>
                </span>
              }
              onDelete={() => handleChipDelete(option.name)}
              deleteIcon={<CloseIcon />}
              sx={{mr: 0 }}
            />
          ) : null;
        })}
        {filterCriteriaOptions.some(option => formik.values[option.name as keyof typeof formik.values]) && (
          <Chip
            label="Clear All"
            onClick={handleClearAll}
            onDelete={() => {handleClearAll()}}
            deleteIcon={<CloseIcon />}
            sx={{ backgroundColor: colorConfigs.buttons.bgInactiveSolid, color: '#fff', fontWeight: 'bold', '&:hover': {
              backgroundColor: colorConfigs.buttons.bgInactiveHoverSolid
            }, }}
          />
        )}
      </Box>


    </Box>
  </Collapse>
) 
}                     
          
        </div>
      </div>
      
                  
  </form>
</FormikProvider>
</Box>
);
};

export default InvoicesFilterBar;