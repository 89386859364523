import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import assets from "../../assets";
import { Alert, Card, InputAdornment, IconButton } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { loginUser } from '../../redux/features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import colorConfigs from '../../configs/colorConfigs';
import versionConfig from '../../configs/versionConfig';
import RareButton from './buttons/RareButton';

function Copyright(props: any) {
  return (
    <Typography data-testid="Typography-nyb4" variant="body2" color="text.secondary" align="center" {...props}>
        Novarumdx is registered trademarks of Novarumdx in many countries of the world.
    </Typography>
  );
};

const SignIn = () => {
    const [isSubmit, setSubmit] = useState(false)
    const [authfailed, setAuthFailed] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const alertContent = "Login failed, please check email and password"
    const dispatch = useDispatch<AppDispatch>();
    const authData = useSelector((state: RootState) => state.auth); // Accessing the auth state
  
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const username = data.get('email') as string;
      const password = data.get('password') as string;
  
      try {
        setAuthFailed(false);
        const resultAction = await dispatch(loginUser({ username, password }));
        unwrapResult(resultAction); // If the promise is rejected, this will throw
        setSubmit(true); // Or navigate to another page
  
    } catch (err) {
        console.error('Failed to login:', err);
        setAuthFailed(true);
      }
    };

    //  Log Auth Data
    React.useEffect(() => {
        console.log(authData);
    }, [authData])


  return isSubmit == false ? (
    <div data-testid="div-ia0r" style={{ marginLeft: "auto", marginRight: "auto", paddingTop:"5%" }}>
        <div data-testid="div-489c" style={{display: "flex", justifyContent: "center"}}>
                    <Box data-testid="Box-zg2j"
                    component="img"
                    sx={{
                    height: "100%",
                    width: "100%",
                    maxHeight: { xs: 433, md: 255 },
                    maxWidth: { xs: 550, md: 394 },
                    mb: 4,
                    }}
                    alt="Novarumdx logo"
                    src={assets.images.rareLogo}
                    />
        </div>
        <Card data-testid="Card-jz1f" sx={{display:'flex', width: "40rem", justifyContent: 'center', boxShadow: 10, paddingBottom:"10%", borderRadius: 5}} >
            <Container data-testid="Container-bhou" component="main" maxWidth="xs">
                <CssBaseline />
                <Box data-testid="Box-qy20"
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Box data-testid="Box-z477" component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1}}>
                {authfailed ? <Alert data-testid="Alert-pnuj" severity='error'>{alertContent}</Alert> : <></> }
                    <TextField data-testid="TextField-mv4b"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField data-testid="TextField-xgil"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton data-testid="IconButton-pnrp"
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box data-testid="Box-c2xp" sx={{display: 'flex', justifyContent: 'center', marginX: 10, marginY: 2}}>
                    <RareButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        >
                        Sign In
                    </RareButton>
                    </Box>
                    <Grid container>
                    <Grid item xs>
                        <Link data-testid="Link-cgqj" href="/forgot-password" variant="body2">
                        Forgot password?
                        </Link>
                    </Grid>
                    </Grid>
                </Box>
                <Typography data-testid="Typography-lk65" variant="body2" color="text.secondary" align="center">
                    {versionConfig.version}
                </Typography>
                </Box>
            </Container>
        </Card>
    </div>
  ) : isSubmit == true && authData.isFreeUser == true ? 
    (
    <Navigate to= "/account/subscription"/>
    )
    : (<Navigate to= "/"/>);
};

export default SignIn;