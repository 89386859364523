import { Alert, AlertTitle, Box, Button, Card, Container, CssBaseline, FormControl, Grid, Link, MenuItem, TextField, ThemeProvider, Typography, useTheme } from '@mui/material';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import assets from '../../../assets';
import { FormEmailTextField, FormPasswordTextField } from '../fields/AuthFields';
import colorConfigs from "../../../configs/colorConfigs";
import versionConfig from "../../../configs/versionConfig";


export default function ResetPasswordCard() {
    const navigate = useNavigate();
    const theme = useTheme();

    const [errors, setErrors] = useState<string| null>(null);

    const validationSchema = Yup.object().shape({
        password1: Yup.string()
            .required("This field is required")
            .min(8, "Password must be at least 8 characters long")
            .matches(/[a-zA-Z]/, "Password must contain at least one letter")
            .matches(/\d/, "Password must contain at least one number"),
        password2: Yup.string()
            .required("This field is required")
            .oneOf([Yup.ref('password1')], "Passwords must match"),
    });

    var { uidb64, token } = useParams()
    console.log(uidb64, token)

    function getCookie(name: any) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            password1: "",
            password2: "",
        },
        validationSchema,
        onSubmit: (values) => {
            const post_data = {
                new_password1: values.password1,
                new_password2: values.password2,
                uid: uidb64,
                token: token,
            }
            api.post('auth/password/reset-confirm/', post_data, {
                headers: {
                    "X-CSRFToken": getCookie('csrftoken'),
                }
            })            
            .then(function (response) {
                console.log(response);
                navigate('/auth/login');
            })
            .catch(function (error) {
                if (error.response?.data?.new_password2[0]) {
                    setErrors(error.response.data.new_password2[0])
                }
                if (error.response) {
                    console.error("Error data:", error.response.data);
                    console.error("Error status:", error.response.status);
                    console.error("Error headers:", error.response.headers);
                } else if (error.request) {
                    console.error("Error request:", error.request);
                } else {
                    console.error('Error', error.message);
                }
            });
        }
    });

    return (
        <FormikProvider value={formik}>
            <div data-testid="div-7o1c" style={{ marginLeft: "auto", marginRight: "auto", paddingTop:"5%" }}>
                <div data-testid="div-94uv" style={{display: "flex", justifyContent: "center"}}>
                            <Box data-testid="Box-jdj7"
                            component="img"
                            sx={{
                            height: "100%",
                            width: "100%",
                            maxHeight: { xs: 433, md: 255 },
                            maxWidth: { xs: 550, md: 394 },
                            mb: 4,
                            }}
                            alt="NovarumDX logo"
                            src={assets.images.novarumLogo}
                            />
                </div>
                <form onSubmit={formik.handleSubmit}>

                <Card data-testid="Card-1mzm" sx={{display:'flex', width: "40rem", justifyContent: 'center', boxShadow: 10, paddingBottom:"10%", borderRadius: 5}} >
                <ThemeProvider theme={theme}>
                            <Container data-testid="Container-gvb1" component="main" maxWidth="xs">
                                <CssBaseline />
                                <Box data-testid="Box-n6jw"
                                    sx={{
                                        marginTop: 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    {errors && (
                                        <Alert data-testid="Alert-0jpp" severity="error" style={{ marginTop: '16px' }}>
                                            <AlertTitle data-testid="AlertTitle-xu9c">{"Error"}</AlertTitle>
                                            {errors}
                                        </Alert>
                                    )}
                                    <FormControl fullWidth >
                                        <div data-testid="div-c94m" className="form-group">
                                            <Field data-testid="Field-3a59" name="password1" label={"Password"} type="text" required className="form-control" component={FormPasswordTextField} />
                                        </div>
                                        <div data-testid="div-9kdz" className="form-group">
                                            <Field data-testid="Field-r220" name="password2" label={"Confirm Password"} type="text" required className="form-control" component={FormPasswordTextField} />                                   
                                        </div>
                                        <div data-testid="div-aq9c" className="form-group">
                                        <Button data-testid="Button-7b1e"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt:3, mb: 2, width: "60%",  marginLeft: '20%', color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold"}}
                                            >
                                            Submit
                                        </Button>
                                        </div>
                                    </FormControl>
                                </Box>
                                <Grid container>
                                    <Grid item xs>
                                        <Link data-testid="Link-zyfm" href="/auth/login" variant="body2">
                                            Return to Login
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Typography data-testid="Typography-mwyd" variant="body2" color="text.secondary" align="center">
                                    {versionConfig.version}
                                </Typography>
                            </Container>
                        </ThemeProvider>
                    </Card>
                </form>
            </div>
        </FormikProvider>
    );
};  