import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";


const TestsGraph = () => {

    const series = [{
        name: 'N/A',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },{
        name: 'Negative',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        name: 'Positive',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ]   
    

    const options: ApexOptions = {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '100%',
              borderRadius: 5,
              borderRadiusApplication: 'end',
            },
          },
          colors: [
             '#9e6f41','#e63535','#7cbc72'
          ],
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 0,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['0', '0.09', '0.181', '0.272', '0.363', '0.454', '0.545', '0.636', '0.727', '0.818', '0.909', '1'],
            title: {
              text: 'T/C (height)'
            }
          },
          yaxis: {
            title: {
              text: 'Frequency'
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            show: false,
          },
          tooltip: {
            y: {
              formatter: function (val:any) {
                return val
              }
            }
          }
        };

    

    return (
      <div data-testid="div-9jjs">
        <div data-testid="div-pm3m" id="chart">
            <ReactApexChart 
            type="bar"
            options={options}
            series={series}
            height={350}
             />
          </div>
        <div data-testid="div-j4a7" id="html-dist"></div>
      </div>
    );
  }

export default TestsGraph

// const options: ApexOptions = {
//     chart: {
//       height: 350,
//       type: 'line',
//       zoom: {
//         enabled: true
//       }
//     },
//   };
  
//   <ReactApexChart
//     options={options}
//     series={series}
//     type="line"
//     height={350}
//   />