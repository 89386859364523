import { Box, Container, createTheme, ThemeProvider } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import assets from '../../../assets';

const HomographyGraph: React.FC<{ graphdata: any; homography: any; image: string }> = ({ graphdata, homography, image }) => {
  const [apexChartData, setApexChartData] = useState<any[]>([]);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  function modelToImageAlt(point: [number, number], homography: number[]): { x: number, y: number } {
    const normZ = homography[6] * point[0] + homography[7] * point[1] + homography[8];
    const transX = (homography[0] * point[0] + homography[1] * point[1] + homography[2]) / normZ;
    const transY = (homography[3] * point[0] + homography[4] * point[1] + homography[5]) / normZ;
    return { x: transX, y: transY };
  }

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setImageDimensions({ width: img.naturalWidth, height: img.naturalHeight });
    };
  }, [image]);

  useEffect(() => {
    if (!graphdata || !homography) return;

    const transformedPoints = graphdata.points.map((point: any) => {
      const transformed = modelToImageAlt(point[0], homography);
      return { x: transformed.x, y: transformed.y };
    });

    setApexChartData(transformedPoints);
  }, [graphdata, homography]);

  const chartData: ApexOptions = {
    chart: {
      id: 'scatter',
      type: 'scatter',
      toolbar: { show: false },
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    xaxis: {
      type: 'numeric',
      min: 0,
      max: imageDimensions.width,
      labels: { show: false },
      axisTicks: { show: false },
      axisBorder: { show: false },
    },
    yaxis: {
      reversed: true,
      min: 0,
      max: imageDimensions.height,
      labels: { show: false },
      axisTicks: { show: false },
      axisBorder: { show: false },
    },
    grid: {
      show: false,
      borderColor: '#000',
      padding: { top: -25, right: 0, bottom: -25, left: -10},
    },
    fill: {
      type: 'image',
      opacity: 1,
      image: {
          src: [assets.images.greenCross], // Path to GreenCross.svg
          width: 10, // Desired width
          height: 10, // Desired height
      },
    },
    markers: {
      size: 5, // Size of the marker
      strokeWidth: 0, // No border
    },
    series: [
      {
        name: 'Points',
        data: apexChartData,
      }
    ],
    
  };
  
  return (
    <ThemeProvider theme={createTheme()}>
      <Container data-testid="Container-wuna"
        component="main"
        maxWidth="xl"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: `${imageDimensions.width}px`,
          height: `${imageDimensions.height}px`,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {/* Image as Background */}
        <Box data-testid="Box-nqrd"
          component="img"
          src={image}
          alt="Background"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain', // Ensures the image fits within the container without distortion
            zIndex: 1,
          }}
        />
        {/* Chart Overlay */}
        <Box data-testid="Box-uzqc"
          sx={{
            position: 'relative',
            zIndex: 2, // Ensure the chart is above the image
          }}
        >
          <ReactApexChart
            width={`${imageDimensions.width}px`}
            height={`${imageDimensions.height}px`}
            type="scatter"
            options={chartData}
            series={chartData.series}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
export default HomographyGraph;
