import {
    Box,
    Button,
    Card,
    Container,
    CssBaseline,
    Dialog,
    FormControl,
    Grid,
    Link,
    ThemeProvider,
    Typography,
    useTheme,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    TextField,
  } from "@mui/material";
  import api from "../../../redux/features/auth/axiosAuthHelper";
  import { Field, FormikProvider, useFormik } from "formik";
  import React, { useState } from "react";
  import { useNavigate } from "react-router-dom";
  import * as Yup from "yup";
  import assets from "../../../assets";
  import { FormEmailTextField } from "../fields/AuthFields";
  import colorConfigs from "../../../configs/colorConfigs";
  import versionConfig from "../../../configs/versionConfig";
    
  function Copyright(props: any) {
  
    return (
      <Typography data-testid="Typography-s6cr"
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        NovarumDX is registered trademarks of NovarumDX in many countries of the world.
      </Typography>
    );
  }
  
  export default function ForgottenPasswordCard() {
    const [showDialog, setShowDialog] = useState(false);
  
    const navigate = useNavigate();
    const theme = useTheme();
  
    const validationSchema = () => {
      return Yup.object().shape({
        email: Yup.string()
          .email("Invalid email format")
          .required("This field is required"),
      });
    };
  
    const handleClose = () => {
      setShowDialog(false);
      navigate("/login");
    };
  
    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema,
      onSubmit: (values) => {
        const post_data = {
          email: values.email,
        };
        console.log(post_data);
        api
          .post("auth/password-reset/", post_data, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        setShowDialog(true);
      },
    });
  
    return (
      <FormikProvider value={formik}>
      <div data-testid="div-46zm" style={{ marginLeft: "auto", marginRight: "auto", paddingTop:"5%" }}>
          <div data-testid="div-au7s" style={{display: "flex", justifyContent: "center"}}>
                <Box data-testid="Box-296g"
                component="img"
                sx={{
                height: "100%",
                width: "100%",
                maxHeight: { xs: 433, md: 255 },
                maxWidth: { xs: 550, md: 394 },
                mb: 4,
                }}
                alt="NovarumDX logo"
                src={assets.images.novarumLogo}
                />
          </div>
          <form onSubmit={formik.handleSubmit}>
          <Card data-testid="Card-vrmm" sx={{display:'flex', width: "40rem", justifyContent: 'center', boxShadow: 10, paddingBottom:"10%", borderRadius: 5}} >
  
              <ThemeProvider theme={theme}>
                <Container data-testid="Container-agr1" component="main" maxWidth="xs">
                  <CssBaseline />
                  {showDialog && (
                    <Dialog data-testid="Dialog-287w"
                      open={showDialog}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle data-testid="DialogTitle-75h3" id="alert-dialog-title">
                        Password Recovery
                      </DialogTitle>
                      <DialogContent data-testid="DialogContent-n9d9">
                        <DialogContentText id="alert-dialog-description">
                          {"We have sent an email to "}{" "}
                          <strong>{formik.values.email}</strong>
                          {" with instructions to reset your password. "}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions data-testid="DialogActions-kxo2">
                        <Button data-testid="Button-4w3j" onClick={handleClose} color="primary" autoFocus>
                          {"Return to Login"}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  <Box data-testid="Box-a54i"
                    sx={{
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box data-testid="Box-7iot" sx={{ mt: 1 }} />
                    <FormControl fullWidth>
                      <div data-testid="div-bnhu" className="form-group">
                        <Field data-testid="Field-jxuv"
                          name="email"
                          type="text"
                          className="form-control"
                          label="Email"
                          autoFocus
                          component={FormEmailTextField}
                        />
                      </div>
                      <div data-testid="div-mbn7" className="form-group">
                      <Button data-testid="Button-becy"
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt:5, mb: 7, width: "60%",  marginLeft: '20%', color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold"}}
                          >
                      Submit
                      </Button>
                      </div>
                    </FormControl>
                  </Box>
                  <Grid container>
                    <Grid item xs>
                      <Link data-testid="Link-yi2r" href="/login" variant="body2">
                        Return to  Login
                      </Link>
                    </Grid>
                  </Grid>
                  <Typography data-testid="Typography-micj" variant="body2" color="text.secondary" align="center">
                      {versionConfig.version}
                  </Typography>
                </Container>
              </ThemeProvider>
            </Card>
          </form>
        </div>
      </FormikProvider>
    );
  }
  