import SignIn from "../../components/common/Signin";
import ResetPasswordCard from "../../components/common/cards/ResetPasswordCard";


type Props = {}

const ResetPasswordPage = (props: Props) => {
  return (
    <div data-testid="div-ko1a" style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <ResetPasswordCard />
    </div>
  );
};

export default ResetPasswordPage;