import { Card, Grid, Typography, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {  TextEditField, TextEditReadOnlyField, OrganisationSelectField, UserTypeSelectField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { UserSessionStorageKey, UserTableParameterStorageService } from '../../../services/sessionStorage';
import RareButton from '../buttons/RareButton';

interface UserDetailProps {
  user_id?: any | null;
  handleRefreshButton: () => void;
  handleBackButton: () => void;
}

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const UserDetailCard: React.FC<UserDetailProps> = ({user_id, handleRefreshButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<any>({})
    const [updatedValues, setUpdatedValues] = useState<any>({})
    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)
    const [isActiveChecked, setIsActiveChecked] = useState(false);
    const [user, setUser] = useState<any>(null);
    const create = !user_id

    
    useEffect(() => {
        if (user_id) {
            const fetchUser = async () => { 
                try {
                    const url = `users/${user_id}`;
                    
                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    
                    const fetchedUser = response.data;
                    setUser(fetchedUser);

                } catch (error) {
                    console.error('Error fetching user', error);
                    setErrors(`No User found with ID ${user_id}`)
                    setTimeout(() => {
                        handleBackButton()
                    }, 4000);
                }
            };
            fetchUser();    
        }
    }, [user_id]);

    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().required("This field is required"),
        });
    }


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && user) ? {
                id: user.id || '',
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                email: user.email || '',
                user_type: user.user_type || '',
                organisation: user.organisation,
                organisation_name: user.organisation_name || '',
                subscription_tier: user.subscription_tier || '',
                date_joined: user.date_joined ? dayjs(user.date_joined).format('YYYY-MM-DD HH:mm:ss') : "", // Cant edit - Set to null if no date
                last_login: user.last_login ? dayjs(user.last_login).format('YYYY-MM-DD HH:mm:ss') : "Not yet logged in", // Cant edit - Set to null if no date
                is_active: user.is_active || false,
            }
            : {
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                user_type: '',
                organisation: authData.organisation,
                organisation_name: '',
                subscription_tier: '',
                date_joined: dayjs(),
                last_login: dayjs(),
                is_active: true,
            },
        validationSchema,
        onSubmit: async (values) => {

            const data = authData.isAdmin ? {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                user_type: values.user_type,
                organisation: values.organisation,
                is_active: values.is_active,
            } : {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                is_active: values.is_active,
            }
  
            const emailChanged = initialValues.email !== updatedValues.email

            const url = user ? `users/${user.id}/` : `users/`
            const method = user ? 'patch' : 'post'

            try {
                console.log("data", data)
                await api ({
                    method,
                    url,
                    data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                    },
                });
                if (create || emailChanged)
                await api.post("auth/password-reset/", {email: values.email}, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                setErrors(null);
                console.log("User successfully updated")
                handleRefreshButton() // reset states and close popup
                const count = UserTableParameterStorageService.get(UserSessionStorageKey.count)
                UserTableParameterStorageService.set(UserSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating lot`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    //  Set checkbox state
    useEffect(() => {
        setIsActiveChecked(formik.values.is_active)
    }, [user, formik.values.is_active]);

  

    // Effect to set initial form values from user to check for form changes
    useEffect(() => {
        setTimeout(() => {
            setInitialValues(formik.initialValues);
            setUpdatedValues(formik.initialValues);
        }, 500);
        setHasMadeChanges(false);
    }, [user]);

    // Update the updatedValues state when formik values change
    useEffect(() => {
        setUpdatedValues(formik.values);
    }, [formik.values]);

    // Function to check if form has changed so the save button becomes active on edit
    const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
        for (const key in formValues) {
            if (key === "expiry_date") {
                const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                const initialDate = create ? dayjs(flatModel[key]).format('YYYY-MM-DD') : dayjs(formValues[key]).format('YYYY-MM-DD');
                if (formDate !== initialDate) {
                    console.log("Changed Date", formDate, initialDate)
                    return true;
                }
            } else {
                if (formValues[key]!= flatModel[key]) {
                    console.log("Changed Data", formValues[key], flatModel[key])
                    return true;
                }
            }
        }
        return false;
    };
            
    // Use Effect to check if any changes ahve been made to the form on Edit
    useEffect(() => {
        if (!create && user) {
            if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                const formChanged = hasFormChanged(initialValues, updatedValues);
                setHasMadeChanges(formChanged);
            }
        }
    }, [updatedValues, initialValues, user]);
    
    // Use Effect set save button active on load if Create and if changes are made on Edit
    useEffect(() => {
        if (create) {
            setHasMadeChanges(true);
        } else {
            setHasMadeChanges(false);
        }
    }, [create]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{}}>
            <Card data-testid="Card-jyer" sx={{ height: "100%", boxShadow: 10, p:2, marginX: 5, marginY: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {errors && (
                <Alert data-testid="Alert-14u4" severity="error" style={{ width: "59.5%" }}>
                    <AlertTitle data-testid="AlertTitle-0m6w">Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div data-testid="div-cvwd" style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card data-testid="Card-ou1a" sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                    <div data-testid="div-m9ut" className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography data-testid="Typography-s0n9" variant="h6" gutterBottom sx={{fontWeight: 'bold'}}>                                            
                                            User Details
                                        </Typography> 
                                        {!create && <Field data-testid="Field-2r07" name="id" disableds type="text" className="form-control" label="User ID" component={TextEditReadOnlyField}  />}
                                        <Box data-testid="Box-behn" sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                            <Field data-testid="Field-0tn0" name="first_name" type="text" className="form-control" label="First Name" component={TextEditField} />
                                            <Field data-testid="Field-9azm" name="last_name" type="text" className="form-control" label="Last Name" component={TextEditField} />
                                        </Box>
                                        <Field data-testid="Field-szmx" name="email" type="text" className="form-control" label="Email*" component={TextEditField} />
                                        { authData.isAdmin && <Field data-testid="Field-z4vz" name="organisation" type="text" className="form-control" label="Organisation" component={OrganisationSelectField} /> }
                                        { authData.isAdmin && <Field data-testid="Field-y2vy" name="user_type" type="text" className="form-control" label="User Type" component={UserTypeSelectField} /> }
                                        <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isActiveChecked} name="is_active"
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                    setIsActiveChecked(!isActiveChecked)
                                                    formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box data-testid="Box-n3ur" display="flex" alignItems="center">
                                                      User is
                                                      <Typography data-testid="Typography-lnrc" 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isActiveChecked ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isActiveChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isActiveChecked ? 'Active' : 'Inactive'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />
                                        </div>
                                </Card>
                            </Grid>
                            {!create && 

                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                            <Card data-testid="Card-jm7o" sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                <div data-testid="div-bxwz" className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography data-testid="Typography-i4m3" variant="h6"  sx={{fontWeight: 'bold'}} gutterBottom>
                                    User Stats
                                </Typography> 
                                <Field data-testid="Field-c848" name="date_joined" type="text" className="form-control" label="Date Joined" component={TextEditReadOnlyField} />
                                <Field data-testid="Field-ncbl" name="last_login" type="text" className="form-control" label="Last Login" component={TextEditReadOnlyField} />
                                {/* <Box data-testid="Box-gbu4" sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                    <Field data-testid="Field-kvt1" name="total_active_dogs" type="text" className="form-control" label="Total Active Dogs" component={TextEditReadOnlyField} />
                                    <Field data-testid="Field-15kn" name="total_dogs" type="text" className="form-control" label="Total Dogs" component={TextEditReadOnlyField} />
                                </Box>
                                <Box data-testid="Box-8wrs" sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                    <Field data-testid="Field-629f" name="total_cycles" type="text" className="form-control" label="Total Cycles" component={TextEditField} />
                                    <Field data-testid="Field-pu0i" name="total_results" type="text" className="form-control" label="Total Results" component={TextEditReadOnlyField} />
                                </Box> */}
                                </div>
                                </Card>
                            </Grid>
                                }
                            </Grid>
                    </div>
                    <div data-testid="div-fh6y" className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <RareButton
                            onClick={() => handleBackButton()}
                            variant="outlined"
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Back
                        </RareButton>
                        <RareButton
                            type="submit"
                            variant="success"
                            disabled={!hasMadeChanges}
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Save Changes
                        </RareButton>
                    </div>
                </Card>
            </form>
        </FormikProvider>
    )
}

export default UserDetailCard;