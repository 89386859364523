import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchUserProfile, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { InvoiceSessionStorageKey, InvoiceSessionStorageModel, InvoiceTableParameterStorageService } from "../../services/sessionStorage";

function PaymentSuccessPage() {
  const dispatch = useDispatch<AppDispatch>();

  const [updatedCardDetails, setUpdatedCardDetails] = useState<any>(() => {
    return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.updatedCardDetails) || false
  });

  useEffect(() => {
    dispatch(fetchUserProfile());
  },[])

  console.log(updatedCardDetails)

  return updatedCardDetails ? (
    <div data-testid="div-l6g0" style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <div data-testid="div-103p" style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Payment Details Updated!</h1>
      <p>Your payment details have been updated successfully.</p>
      <a href="/account/subscription">Continue</a>
    </div>
    </div>
    ) : (
      <div data-testid="div-m45k" style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <div data-testid="div-5aoy" style={{ textAlign: "center", marginTop: "50px" }}>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase. Your transaction was successful.</p>
        <a href="/account/subscription">Continue</a>
      </div>
      </div>
    )
}

export default PaymentSuccessPage;
