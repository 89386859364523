import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { Box } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import assets from '../../../assets';
import colorConfigs from '../../../configs/colorConfigs';


const ResultSourceRadarGraph = () => {

    const series = [36, 57, 42]
    

    const options: ApexOptions = {
          chart: {
            type: 'pie',
            width: 400,
            height: 380,
            toolbar:{
              show:false,
            },
          },
          labels:['iOS', 'Android', 'Reprocessed'],
          colors:[colorConfigs.buttons.bgInactiveSolid, colorConfigs.dashboard.dark_red, colorConfigs.dashboard.mid_grey],
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#111']
            },
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex] + ' results'
            },
            background: {
              enabled: true,
              foreColor: '#fff',
              borderWidth: 0
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -30,
              },
            },
          },
          stroke:{
            colors:['#c0c0c0']
           },
          legend:{
            labels: {
              colors: [colorConfigs.dashboard.dark_grey, colorConfigs.dashboard.dark_grey, colorConfigs.dashboard.dark_grey],
              useSeriesColors: false
          },
          },
          xaxis: {
            categories: ['iOS', 'Android', 'Reprocessed'],
            labels:{
              show: true,
              style: {
                colors: ["#000","#000","#000"],
                fontSize: "14px",
                fontFamily: 'Arial',
                fontWeight: 'Bold',
              }
            },
          },
          tooltip: {
            y: {
              formatter: function (val:any) {
                return val
              }
            }
          }
        };

    

    return (
      <Box data-testid="Box-5acv" sx={{pl:12, pt:6}}>
        <div data-testid="div-3xzw" id="chart">
            <ReactApexChart 
            type="pie"
            options={options}
            series={series}
            height={options.chart?.height}
            width={options.chart?.width}
             />
          </div>
        <div data-testid="div-hmuf" id="html-dist"></div>
      </Box>
    );
  }

export default ResultSourceRadarGraph