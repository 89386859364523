// src/Cancel.js
import React from 'react';

function PaymentCancelPage() {
  return (
    <div data-testid="div-tpx2" style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Payment Canceled</h1>
      <p>Your payment was not completed. You can try again or contact support.</p>
      <a href="/">Continue</a>
    </div>
  );
}

export default PaymentCancelPage;
